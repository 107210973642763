(function () {
    'use strict';
    angular
        .module('app.products', [
            'app.products.service',
            'app.products.add',
            'app.products.price',            
            'app.products.group',
            'app.products.create-group',
            'app.products.details'

        ])
})();
