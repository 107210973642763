(function () {
    'use strict';

    angular
        .module('app.admin-managers.list',[])
            .config(function($stateProvider) {
                $stateProvider
                    .state('admin.managers', {
                        url: "/managers",
                        cache:false,
                        views: {
                              'menuContent': {
                                    template:`
                                    <ion-view title="Territory Managers">
                                        <managers-list></managers-list>
                                    </ion-view>`
                              }
                        }
                    })
            })
            .component('managersList',{
                templateUrl:'views/admin-managers/list.html',
                controller: ComponentCtrl
            })

    function ComponentCtrl (Manager, ngNotify, $ionicLoading) {


        var vm = this

        // load managers list
        getManagers()

        function getManagers () {
            $ionicLoading.show()
            Manager.all()
                .then(data => vm.managers = data.data )
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }


        vm.addManager = function () {
            $ionicLoading.show()
            Manager.create(vm.manager)
                .then(function (data) {
                    ngNotify.success(data)
                    vm.showForm = false
                    getManagers()
                })
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

    }

})();
