(function () {
    'use strict';
    angular
        .module('app.products.group', [])
            .config(Config)
            .component('groupDetails', {
                templateUrl: 'views/products/group-details.html',
                controller: Ctrl
            })

    function Ctrl ($stateParams, ngNotify, Product, ionicToast, $localStorage, $timeout) {

         var vm = this

         vm.$onInit = function () {

             vm.matching = []

            vm.catalogs = $localStorage.catalogs

            vm.catalogs.productTypes.push({ id: null, name: 'Select option'})

             // get the group details
             Product.group($stateParams.id)
                .then(result => {
                    vm.group = result.data                
                    console.log(vm.group)
                    $timeout(vm.searchMatching,2500)
                })
                .catch(ngNotify.danger)


            // get the products in order to allow the user to add new items to the group
            // vm.products = $stateParams.products

            if (!vm.products) {
                Product.all()
                    .then(result => vm.products = result.data )
                    .then( () => markSelectedProducts() )
                    .catch(ngNotify.danger)
            } else {
                markSelectedProducts()
            }

         }

         vm.save = function () {
             console.log(vm.group);
             Product.updateGroup(vm.group)
                .then(ngNotify.success)
                .catch(ngNotify.danger)
         }

        vm.searchMatching = function () {

            console.log('Search matching products')            
            
            vm.matching = vm.products

            if (vm.group.vendor) {
                vm.matching = vm.matching.filter(p => p.vendor === parseInt(vm.group.vendor))
            }            

            if (vm.group.productTypeId) {
                vm.matching = vm.matching.filter(p => p.productTypeId === parseInt(vm.group.productTypeId))
            }

            // vm.matching = vm.matching.filter(p => p.productTypeId = vm.group.productTypeId)            

            if (vm.group.allProducts) {
                vm.matching = vm.products
            }

            // if (!vm.group.allProducts && !vm.group.vendor && !vm.productTypeId) {
            //     vm.matching = []
            // }

            console.log(vm.matching.length)


        }

         vm.toggleProduct = function (productId, product ) {
             Product.toggleProductGroup({ productId: productId, groupId: $stateParams.id })
                .then(result => {
                    ionicToast.show(result.data)
                   console.log(result.data);
                    if (result.data.indexOf('removed')>-1) {
                        vm.group.productList = vm.group.productList.filter(pl => pl.productId !== productId)
                    } else if (product) {
                        vm.group.productList.push({
                            groupdId: $stateParams.id,
                            productId: productId,
                            // not necesary for the backend but important for displaying the info on the frontend
                            product: {
                                modelNo: product.modelNo,
                                description: product.description
                            }
                        })
                    }
                    markSelectedProducts()
                })
                .catch(ngNotify.danger)
         }

         vm.removeProduct = function (prod) {
             vm.group.productList = vm.group.productList.filter(pl => pl.id !== prod.id)
             ionicToast.show('Product removed', 'bottom')
             markSelectedProducts()
         }

         vm.addToGroup = function (item) {

             // verify if it is not already on the list
             let alreadyOnTheList = vm.group.productList.find(pl => pl.productId === item.id)
             if (alreadyOnTheList) {
                 ionicToast.show('Product already on the group')
                 return ;
             }

             vm.group.productList.push({
                 groupdId: $stateParams.id,
                 productId: item.id,
                 // not necesary for the backend but important for displaying the info on the frontend
                 product: {
                     modelNo: item.modelNo,
                     description: item.description
                 }
             })

             vm.group.productList = _.uniqWith(vm.group.productList, _.isEqual)

             markSelectedProducts()
         }

        function markSelectedProducts () {

            if (!vm.products) {
                return ;
            }

            vm.products.forEach(p => {
                if (vm.group && vm.group.productList) {
                    let isActive = vm.group.productList.find(activeProd => activeProd.productId === p.id )
                    if (isActive) { p.selected = true }
                }
            })
        }

    }

    function Config ($stateProvider) {
        $stateProvider
            .state('admin.group', {
                url: "/group/:id",
                cache:false,
                params: {
                    products: null
                },
                views: {
                      'menuContent': {
                            template:`
                            <ion-view title="Edit Group">
                                <group-details></group-details>
                            </ion-view>`
                      }
                  }
              })
    }
})();
