(function () {
  'use strict';
  angular
    .module('app.auth',[
      'app.auth.service', // comunicación con el backend para autenticarnos
      'app.auth.login', // iniciar sesión con una cuenta creada previamente
      'app.auth.signup', // crear una nueva cuenta
      'app.auth.interceptor', // send user to login if server returns "unauthorized"
      'app.auth.logout', // destroy $localStorage data to log the user out
      'app.auth.routes',
      'app.auth.maintenance', // display message when admins block access to the quoting features
      // 'app.auth.start',
      'app.auth.device', // display a message when the user try to access the system from a different device than the registered one
      'app.auth.pending', // user will see this screen until admin approves access
      'app.auth.password', // forgot password
      'app.auth.directives', // show hide password
      // 'app.auth.facebook' // login/signup using a facebook account
    ]);
})();
