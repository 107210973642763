(function () {
    'use strict';

    angular
        .module('app.products.details', [])
            .component('productDetails',{
                template: `
                <div class="product-details">
                    <div class="row">
                        <div class="col col-25">
                            <img ng-src="{{$ctrl.product.media||'img/404.png'}}" width="100%">
                        </div>
                        <div class="col col-75">
                            <h4 align="center" class="hi-title" ng-if="$ctrl.loading">
                                <ion-spinner></ion-spinner><br>Loading product details</h4>
                            <h4 class="hi-title">{{::$ctrl.product.modelNo}}</h4>
                            <h4 class="hi-error" ng-if="!$ctrl.product.modelNo && !$ctrl.loading">
                                {{$ctrl.model}} was not found in our stock
                            </h4>

                            <admin-button
                                ng-if="$ctrl.product == 'notfound'"
                                state="admin.pricing"
                                params="{ model: $ctrl.model }"
                                text="Add product to the system"></admin-button>

                            <p align="center" style="padding:5px;" ng-if="!$ctrl.loading">
                                <admin-button
                                state="admin.product"     
                                ng-if="$ctrl.product.modelNo"                           
                                params="{ modelNo: $ctrl.product.modelNo }"
                                class="button-assertive"
                                text="Edit"></admin-button>
                                {{::$ctrl.product.description}}
                            </p>
                        </div>
                    </div>

                                     
                        
                    <download-file 
                        title="Consumer Brochure" 
                        style="width:50%" 
                        file="$ctrl.product.brochure"></download-file>
                    
                    <download-file 
                        title="Reference guide"                             
                        file="$ctrl.product.guide"></download-file>
                    
                    <br>
                    

                    <download-file 
                        title="Installer's Manual" 
                        style="width:50%"  
                        file="$ctrl.product.manual"></download-file>

                    <download-file 
                        title="Submittal" 
                        file="$ctrl.product.submittal"></download-file>

                    
                    
                    
                </div>`,
                controller:ComponentCtrl,
                bindings: {
                    model: '='
                }
            })

            // <a class="button button-assertive button-outline button-small mhover"
            //                 ng-if="$ctrl.product.brochure"
            //                 ng-click="$ctrl.download($ctrl.product.brochure)">
            //                 Consumer Brochure
            //             </a>
            // <a class="button button-assertive button-small mhover button-outline"
            //                 ng-if="::$ctrl.product.guide"
            //                 target="_blank"
            //                 href="{{$ctrl.cdn + $ctrl.product.guide}}">
            //                 Quick Reference Guide
            //             </a>

            // <div class="button-bar" style="padding:5px;">
                        
            //             <a class="button button-assertive button-small mhover button-outline"
            //                 ng-if="::$ctrl.product.manual"
            //                 target="_blank"
            //                 href="{{$ctrl.cdn + $ctrl.product.manual}}">
            //                 Installer's Manual
            //             </a>
            //             <a class="button button-assertive button-outline button-small mhover"
            //                 target="_blank"
            //                 ng-if="$ctrl.product.submittal"
            //                 href="{{ ::$ctrl.cdn  + $ctrl.product.submittal }}">
            //                 Submittal
            //             </a>                        
            //         </div>

    function ComponentCtrl (Product, CDN, $localStorage, File, ngNotify, $ionicLoading) {

        var vm = this
        vm.cdn = CDN

        vm.$onInit = function () {

            vm.loading = true            

            if ($localStorage.user && $localStorage.user.userRoles) {
                let isAdmin = $localStorage.user.userRoles.find( role => role.roleId === 1   )

                vm.isAdmin = isAdmin && isAdmin.roleId && isAdmin.roleId === 1 ? true : false;
            }

            Product.getPrice(vm.model)
                .then(data => {

                    vm.product = data.data

                    if (vm.product && vm.product.specs && vm.product.specs.indexOf('First Company')> -1) {
                        console.log('First company');
                        vm.product.type = null
                    }

                    console.log(vm.product);

                    if (!vm.product) {
                        console.log(`Price not found for ${vm.model}`)
                        vm.product = 'notfound'
                    }

                    if (vm.product && vm.product.media) {
                        vm.product.media = CDN + vm.product.media
                    }

                    // quick reference guide
                    // we are doing this on the backend instead
                    // if (vm.product.vendor === 831) vm.product.qrg =  vm.product.type.guide
                    // if (vm.product.vendor === 323) vm.product.qrg =  vm.product.type.guideAmeristar
                    

                })
                .catch(err => console.error(err.data) )
                .finally(() => vm.loading = false);

        }

        vm.$onChange = function () {
            console.log('change!');
        }

        vm.download = function (name) {
            $ionicLoading.show()
            File.download(name)
                .then(result => {
                    var downloadedFile = new Blob([result.data], { type: 'application/pdf' });
                    saveAs(downloadedFile, _.trim(name));
                })
                .catch(err => {
                    ngNotify.error('You might need to wait a few minutes before downloading more files.')
                    console.error(err)
                })
                .finally($ionicLoading.hide)
        }


    }

})();
