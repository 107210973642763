(function () {
    'use strict';
    /*
    Add accessories after choosing a complete system
    */
    angular
        .module('app.quotes.accessories',[])
        .config(function ($stateProvider) {

            
            $stateProvider
                .state('app.accessories', {
                    url: '/accessories',                                        
                    cache:false,
                    views: {
                        'menuContent': {
                            template:`
                                <ion-view title="Add Accessories"><ion-content><quote-accessories></quote-accessories></ion-content></ion-view>`
                        }
                    }
                })
        })
        .component('quoteAccessories',{
            templateUrl:'views/accessories/accessories.html',
            controller: Ctrl
        })

    function Ctrl (Product, ngNotify, Quote, $ionicPopup, $ionicLoading, $stateParams, _, Cart, Helper, $timeout, $state) {

        var vm = this      
        
        const CategoryID = 6

        vm.$onInit = function () {

            console.log('accessories component')
            
            $ionicLoading.show()
            getProducts(CategoryID)
        }

        vm.addToCart = function () {
            vm.products.forEach(prod => {
                prod.productTypeId = CategoryID
                if (prod.selected) Cart.add(prod)
            })

            $state.go('app.cart')
        }

        vm.filterOut = function () {            
            vm.filtered = vm.products;

            console.log(vm.form);

            Object.keys(vm.form).forEach(function (key) {
                if (vm.form[key] === 'Choose option') delete vm.form[key]
                // based on the selection, we will filter the array, each selection is represented as an attribute on this object
                if (vm.form[key]) {
                    let selectedValue = String(vm.form[key]).toUpperCase()

                    vm.filtered = vm.filtered.filter(f => String(f[key]).toUpperCase() === selectedValue)
                }
            });

            groupResults()
        }

        vm.resetField = function (field) {
            delete vm.form[field]
            vm.filterOut()
            groupResults()
        }

        vm.resetFilters = function () {
            vm.selected = null
            vm.form = {}
            vm.filterOut()
            groupResults();
        }

        vm.select = function (selected) {
            vm.selected = null

            // kind of a $scope.$apply to solve a bug that was causing the product box to not update
            $timeout(()=>{                
                vm.selected = selected
            }, 100)

        }

        

        function getProducts (productTypeId) {

            

            $ionicLoading.show()
            Product.getByTypeId(productTypeId)
                .then( data => {

                    // let productsWithSpecs = data.data.filter(p => p.specs != null)
                    vm.products = data.data.map(Product.parseSpecs)
                    vm.products = vm.products.filter(prod => prod.description != null)
                    vm.filtered = vm.products

                    // vm.exampleProd = vm.products.find(p => Object.keys(p).length > 4)
                    vm.exampleProd = vm.products[0]                                    

                    groupResults()
                })
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }


        // group columns for populating the dropboxes
        function groupResults() {

            let fields = Object.keys(vm.exampleProd)

            // array to populate form select box for filtering the results
            vm.dropdown = {}

            for (let item in fields) {
                let field = fields[item]                
                vm.dropdown[field] = Object.keys(_.groupBy(vm.filtered, field))                
                vm.dropdown[field] = vm.dropdown[field].filter(o => o!='undefined')
                
                vm.dropdown[field] = vm.dropdown[field].sort(Helper.naturalSort)
                
            }

        }


        

    }

})();
