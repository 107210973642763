(function () {
    'use strict';
    angular
        .module('app.main',[])
            .controller('AppCtrl', AppCtrl)

    function AppCtrl ($localStorage, Auth, $state, $scope, Idle, $ionicHistory, $ionicPopup, CDN) {

        var vm = this

        $scope.events = [];
        $scope.idle = 5000;
        $scope.timeout = 200;


        if ($localStorage.displayMarkup && $localStorage.user) {
            vm.logo = CDN + $localStorage.user.profilePicture
        } else {
            vm.logo = 'img/logo2.png'
        }
        
        $scope.$on('IdleStart', function() {
            console.log('idle start');
            let popup = $ionicPopup.alert({ 
                title: 'Your session is going to expire',
                subTitle: 'If no action is taken we will log out your account for security reasons'                
            });
            let closeAlertIn = ($scope.timeout * 1000) - 2000
            setTimeout(()=> popup.close(),   closeAlertIn)
            
        });

        $scope.$on('IdleEnd', function() {
          console.log('idle end');
        });

        $scope.$on('IdleWarn', function(e, countdown) {
          console.log('idle warn');
        });

        $scope.$on('IdleTimeout', function() {
            if ($localStorage.token && $localStorage.user) {
                console.warn('Session has expired')
                
                $ionicPopup.alert({
                    title: 'Your session has expired.',
                    subTitle: 'Please login again to continue'
                })

                console.log('idle timeout');
                $localStorage.user = null;
                $localStorage.user = {};
                $localStorage.token = null;
                console.log('Log out!')

                $ionicHistory.nextViewOptions({
                    disableAnimate: true
                });
                $state.go('auth.login', {});
            }
            
        });

        $scope.$on('Keepalive', () => console.log('idle keepalive') );

        

        $scope.$watch('idle', function(value) {
          if (value !== null) Idle.setIdle(value);
        });

        $scope.$watch('timeout', function(value) {
          if (value !== null) Idle.setTimeout(value);
        });
        

        // display different interface if the app is accessed from a desktop/laptop
        if (ionic.Platform.isIOS() || ionic.Platform.isAndroid()) {
            vm.isMobile = true
        } else {
            vm.isMobile = false
        }

        if (window.innerWidth < 750) {
            vm.displayMenuButton = true
        }

        if ($localStorage.user && $localStorage.user.userRoles) {
            vm.isAdmin = $localStorage.user.userRoles.find( role => role.roleId === 1   )

            vm.isManager = $localStorage.user.userRoles.find( role => role.roleId === 3 )

            
            if (vm.isAdmin) {
                console.log('Administrator permissions')
            }
        }      

        
        // Auth.getFingerPrint()
        //     .then(Auth.verifyDeviceAccess)                    
        //     .then(ok => console.log(ok.data))
        //     .catch(err => {
        //         console.error(err.data)
        //         if(err && err.data && err.data.message && err.data.message ==='Unauthorized device') {
        //             $state.go('auth.device')
        //         }
        //         if(err && err.data && err.data.message && err.data.message ==='Device already submitted, but not approved yet') {
        //             $state.go('auth.device')
        //         }
        //     })
        


    }

})();
