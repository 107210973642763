(function () {
    'use strict';

    angular

        .module('app.products.service', [])

            .factory('Product', function ($http, API) {

                return {
                    all: function () {
                        let endpoint = `${API}/api/products`;
                        return $http.get(endpoint);
                    },
                    getPrice: function (id) {
                        let endpoint = `${API}/api/products`;
                        return $http.patch(endpoint + '?ModelNo=' + id); // { modelNo: id }
                    },
                    show: function (id) {
                        let endpoint = `${API}/api/products/${id}`;
                        return $http.get(endpoint);
                    },
                    getByModelNo: function (model) {                        
                        let endpoint = `${API}/api/products/model?model=${model}`;
                        return $http.get(endpoint);
                    },
                    getByTypeId: function (type) {
                        let endpoint = `${API}/api/products?productTypeId=${type}`;
                        return $http.get(endpoint);
                    },
                    create:function (data) {
                        let endpoint = `${API}/api/products`;
                        return $http.post(endpoint, data);
                    },
                    update: function (data) {
                        let endpoint = `${API}/api/products/${data.id}`;
                        return $http.put(endpoint, data);
                    },
                    updatePrice: function (data) {
                        let endpoint = `${API}/api/products/${data.id}`;
                        return $http.patch(endpoint, data);
                    },
                    remove: function (id) {
                        let endpoint = `${API}/api/products/${id}`;
                        return $http.delete(endpoint);
                    },
                    // get an object identifying which fields correspond to each prduct
                    getFields: function () {
                        let endpoint = `${API}/api/products/fields/all`;
                        return $http.get(endpoint);
                    },
                    groups: function () {
                        let endpoint = `${API}/api/admin/groups`;
                        return $http.get(endpoint);
                    },
                    group: function (ID) {
                        let endpoint = `${API}/api/admin/groups/${ID}`;
                        return $http.get(endpoint);
                    },
                    addGroup: function (data) {
                        let endpoint = `${API}/api/admin/groups`;
                        return $http.post(endpoint, data);
                    },
                    deleteGroup: function (ID) {
                        let endpoint = `${API}/api/admin/groups/${ID}`;
                        return $http.delete(endpoint);
                    },
                    updateGroup: function (data) {
                        let endpoint = `${API}/api/admin/groups/${data.id}`;
                        return $http.put(endpoint, data);
                    },
                    addProductToCategory: function (data) {
                        let endpoint = `${API}/api/admin/groups`;
                        return $http.post(endpoint, data);
                    },
                    toggleProductGroup: function (data) {
                        let endpoint = `${API}/api/admin/groups/${data.groupId}`;
                        return $http.patch(endpoint, data);
                    },
                    addSpecs: function (data) {                        
                        let endpoint = `${API}/api/products/specs?ModelNo=${data.ModelNo}`;
                        return $http.put(endpoint, data);
                        
                    },
                    parseSpecs: function (prod) {
                        try {
                            if (!prod.specs) return prod;
                            prod.data = JSON.parse(prod.specs)
                            // add json properties to the main object to facilitate system filters
                            _.each(prod.data, (value, key) => prod[_.camelCase(key)] = value )

                            delete prod.data;          
                            delete prod.specs;                            
                        } catch (e) {
                            console.log(prod.specs);
                            console.error(e);
                        }
                    
                        return prod

                    }
                }

            })
})();
