(function () {
  'use strict';
  angular
    .module('app.auth.password',[])
      .component('authPassword',{
        template:`
        <form name="forgot_password_form" class="" novalidate>
          <div class="item item-body">
            <label class="item item-input">
              <input type="email" placeholder="Email" name="user_email" ng-model="$ctrl.email" required>
            </label>
          </div>
          <div class="item item-body bottom-content">
            <button type="submit" class="button button-positive button-block"
              ng-click="$ctrl.recoverPassword()"
              ng-disabled="forgot_password_form.$invalid">
              Recover it
            </button>
          </div>
        </form>`,
        controller:'AuthPasswordCtrl'
      })
      .controller('AuthPasswordCtrl',function (ngNotify,Auth,$location,$ionicLoading) {

        var vm = this
        let location = $location.absUrl().split("#")[0] + "#"
        console.log(location)

        vm.recoverPassword = function () {
          $ionicLoading.show()
          let location = $location.absUrl().split("#")[0] + "#"
          console.log(location)
          Auth.password(vm.email,location)
            .success(function (data) {
              ngNotify.success(data)
            })
            .error(function (err) {
              ngNotify.error('An error ocurred, please verify you entered the correct email')
              console.error(err)
            })
            .finally($ionicLoading.hide)
        }

      });
})();
