(function () {
  'use strict';
  angular
    .module('app.auth.logout',[])
      .component('logout',{
        template:`
          <button class="button button-assertive button-full"
            ng-click="$ctrl.login()" >
            <i class="icon ion-log-out" style="color:#fff;font-size:15px"></i>
            Logout
          </button>`,
        controller:'LogOutCtrl'
      })
      .controller('LogOutCtrl',function ($localStorage, $state, $ionicHistory) {

        $localStorage.user = null;
        $localStorage.user = {};
        $localStorage.token = null;
        console.log('Log out!')

        $ionicHistory.nextViewOptions({
            disableAnimate: true
        });
        $state.go('auth.login');

      });
})();
