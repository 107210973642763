(function () {
  'use strict';

  angular
    .module('app.auth.directives',[])

      .directive('showHideContainer', function(){
        return {
          scope: {
          },
          controller: function($scope, $element, $attrs) {
            $scope.show = false;

            $scope.toggleType = function($event){
              $event.stopPropagation();
              $event.preventDefault();

              $scope.show = !$scope.show;

              // Emit event
              $scope.$broadcast("toggle-type", $scope.show);
            };
          },
          template: `
          <div class="show-hide-input" ng-transclude></div>
          <a class="toggle-view-anchor" on-touch="toggleType($event)">
          	<span class="ion-eye-disabled" ng-show="show"></span>
          	<span class="ion-eye" ng-show="!show"></span>
          </a>`,
          restrict: 'A',
          replace: false,
          transclude: true
        };
      })

      .directive('showHideInput', function(){
        return {
          scope: {
          },
          link: function(scope, element, attrs) {
            // listen to event
            scope.$on("toggle-type", function(event, show){
              var password_input = element[0];
              var input_type = password_input.getAttribute('type');

              if(!show)
              {
                password_input.setAttribute('type', 'password');
              }

              if(show)
              {
                password_input.setAttribute('type', 'text');
              }
            });
          },
          require: '^showHideContainer',
          restrict: 'A',
          replace: false,
          transclude: false
        };
      })
})();
