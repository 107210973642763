(function () {
  'use strict';
  angular
    .module('app.user.service',[])
      .factory('User', function ($http, API, $localStorage) {
        return {          
          // get public profile, no sensitive information is sent on this route
          profile: function () {
            var endpoint = API + '/api/account/profile';
            return $http.get(endpoint);
          },
          update:function (data) {
            let endpoint = API + '/api/account/profile'
            return $http.put( endpoint, data)
          },
          // update user's password
          password:function (data) {
            let endpoint = API + '/api/account/resetpassword'
            return $http.put( endpoint, data)
          },
          get:function (id) {
            return $http.get( API + '/users/get/' + id);
          },
          // *territory* managers
          managers:function () {
            return $http.get( API + '/api/managers');
          },
          register:function (data) {
            return $http({
              method:'POST',
              url:'Account/Register',
              data:data
            });
          },
          isAdmin: function () {
            if ($localStorage.user && $localStorage.user.userRoles) {              
              let isAdmin = $localStorage.user.userRoles.find( role => role.roleId === 1   )
                
              return isAdmin && isAdmin.roleId && isAdmin.roleId === 1 ? true : false;                
            } else {
              return false
            }
          }

        };
      });
})();
