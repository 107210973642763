(function () {
    'use strict';
    angular
        .module('app.quotes.catalogs', [])
            .component('loadCatalogs', {
                template:'',
                controller: function (ngNotify, Quote, $localStorage, $log) {

                    var vm = this

                    vm.$onInit = function () {
                        Quote.catalogs()
                            .then(data => $localStorage.catalogs = data.data)
                            .then(() => {                                
                                $localStorage.catalogs.unitTypes
                                    .forEach(unit => unit.fields = JSON.parse(unit.fields) )
                                                                
                                $localStorage.catalogs.productTypes
                                    .forEach(pt => pt.fields = JSON.parse(pt.fields||null) )                                                                

                                $log.log('Catalogs updated')
                            })
                            .catch(err => {
                                console.error(err.data)
                                ngNotify.danger(err)
                            })
                    }

                }
            })
})();
