(function () {
    'use strict';

    angular
        .module('app.quotes.packaged-unit',[])
            .config(SystemConfig)
            .component('packagedUnit',{
                templateUrl:'views/complete-systems/packaged-unit.html',
                controller:ComponentCtrl
            })

    function ComponentCtrl (Quote, ngNotify, $ionicLoading, QuoteFilters, Cart, $stateParams, System) {

        var vm = this

        vm.filtered = false

        vm.$onInit = function () {

            // determine what condensers should be loaded based on the unit type
            // if no unit type is specified then we will load PU - Cooling system
            var UnitTypeId = $stateParams.UnitTypeId || 1

            vm.loadingSubmittals = false

            // active tab
            if (parseInt(UnitTypeId) === 1) {
                vm.active = 'gas-electric'
            } else {
                vm.active = 'heat-pump'
            }


            // forms
            vm.form = {};
            // the idea is to store in this object the selected items for each subcategory
            vm.selected = {}

            // initialize ranges
            QuoteFilters.resetRanges(vm)

            System.productsFromUnitType(UnitTypeId)
                .then(result => {
                    vm.all = result.data.map(System.parseProperties)
                    vm.filtered = vm.all

                    if ($stateParams && $stateParams.selected) {
                        console.log($stateParams.selected);
                        let filterableFields = [
                            'packagedUnitBrand',
                            'tonnage',
                            'coolingStages',                            
                            'hspf',
                            'packagedUnitModelNo'                            
                        ]

                        filterableFields.forEach(field => vm.form[field] = $stateParams.selected[field])
                        vm.selected = $stateParams.selected
                        vm.filterOut(vm);
                    }

                    groupResults()
                })
                .catch(ngNotify.danger)

        }

        vm.select = item => {
            console.log(item)
            vm.selected = item
            vm.selected.models = System.getModels(item)
        }


        // remove items from vm.filters list based on the user form selections
        vm.filterOut = function (ctrl) {
            QuoteFilters.filterOut(ctrl)
            // show only possible answers for the unchecked fields
            groupResults()
        }

        // add selected item to the shopping cart
        vm.addToCart = Cart.add

        // reset all the filters already set
        vm.resetFilters = function (ctrl) {
            vm.filtered = vm.all
            QuoteFilters.resetFilters(ctrl)
            QuoteFilters.resetRanges(vm)
            groupResults()
        }
        
        // clear EER or SEER range
        vm.resetRange = function (range) {
            vm[range] = {}            
            vm.filterOut(vm)
            groupResults()
        }
        

        // reset a single filter
        vm.resetField = function (field, ctrl) {
            QuoteFilters.resetField(field, ctrl)
            QuoteFilters.resetRanges(vm)
            // show only possible answers for the unchecked fields
            groupResults()
        }

        // group columns for populating the dropboxes
        function groupResults() {

            let fields = [
                'tonnage',
                'seer',
                'eer',
                'coolingStages',
                'packagedUnitBrand',
                'packagedUnitModelNo',
                'stageId',
                'voltage',
                'hspf'
            ]

            // array to populate form select box for filtering the results
            vm.dropdown = {}

            for (let item in fields) {
                let field = fields[item]
                vm.dropdown[field] = Object.keys(_.groupBy(vm.filtered, field)).sort();
            }

        }

    }


    function SystemConfig ($stateProvider) {
        $stateProvider
            .state('app.packaged-unit', {
                url: '/packaged-unit?UnitTypeId',
                cache:false,
                params:{
                    selected: null
                },
                views: {
                      'menuContent': { template:`<ion-view title="Build Packaged Unit System"><packaged-unit></packaged-unit></ion-view>` }
                }
            })
    }
})();

