(function () {
    angular
        .module('app.files.manager', [])
            .config(Config)
            .component('fileManager',{
                templateUrl: 'views/files/file-manager.html',
                controller: Ctrl
            })

    function Ctrl (File, $ionicLoading, CDN, Upload, ngNotify, API, $filter, $stateParams, $state) {

        var vm = this
        vm.cdn = CDN
        vm.search = ''

        vm.$onInit = function () {
            if ($stateParams.folder) {
                vm.selectFolder($stateParams.folder)
            }

            if ($stateParams.search) {
                vm.search = $stateParams.search
            }


        }

        vm.back = function () {
            console.log('Back');
            vm.folder = null
            vm.files = null
            $state.go('.', { folder: null }, { notify: false });
        }

        vm.selectFolder = function (category) {
            vm.folder = category

            $state.go('.', { folder: category }, {notify: false});

            File.getFilesFromFolder(category)
                .then(result => vm.files = result.data)
                .then(()=> vm.files.forEach(function (f) {
                    f.url = CDN + f.key
                    f.name = $filter('removePDFExtension')(f.key)
                    return f;
                }))
                .catch(ngNotify.danger)
        }

        vm.delete = file => {            
            let sure = confirm(`Do you really want to delete this file: ${file}?`)
            if (!sure) return ;

            $ionicLoading.show()
            File.delete(file)
                .then(result => vm.selectFolder(vm.folder))
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

        vm.upload = function () {
            console.log(vm.file);
            if (!vm.folder) { return ; }
            

            if (vm.file.length === 1){

                // upload single file
                $ionicLoading.show();
                Upload.upload({
                    url: `${API}/api/files?fileType=${vm.folder}&fileName=${vm.file[0].name}`,
                    method: 'POST',
                    file: vm.file
                }).then(function (resp) {
                    ngNotify.success(resp.data.message)
                    $ionicLoading.hide()

                    vm.selectFolder(vm.folder)
                }, function (resp) {
                    $ionicLoading.hide()
                    console.log('Error status: ' + resp.status);
                    ngNotify.error('We could not upload the file, please try again later')
                    console.error(resp.data);
                });

            } else if (vm.file.length>1) {

                // upload multiple files and refresh when it finishes
                $ionicLoading.show();
                let countCompleted = 0
                vm.file.forEach(file => {
                    Upload.upload({
                        url: `${API}/api/files?fileType=${vm.folder}&fileName=${file.name}`,
                        method: 'POST',
                        file: file
                    }).then(function (resp) {
                        ngNotify.success(resp.data.message)
                        countCompleted += 1                                                                        
                        if (countCompleted >= vm.file.length) {
                            $ionicLoading.hide()
                            vm.selectFolder(vm.folder)
                        }
                    }, function (resp) {                        
                        console.log('Error status: ' + resp.status);
                        console.error(resp.data);
                        countCompleted +=1                        
                        if (countCompleted >= vm.file.length) $ionicLoading.hide()
                    });
                })
            }

            
        }

    }
    function Config ($stateProvider) {
        $stateProvider
            .state('admin.file-manager', {
                url: "/file-manager?folder?search",
                cache:false,
                views: {
                      'menuContent': {
                            template:`
                            <ion-view title="Admin - File Manager">
                                <file-manager></file-manager>
                            </ion-view>`
                      }
                }
            })
    }
})();
