(function () {
    'use strict';
    angular
        .module('app.products.add', [])
            .component('addProduct', {
                templateUrl: 'views/products/add-product.html',
                controller: Ctrl
            })

    function Ctrl (Product, ngNotify, $ionicLoading, $stateParams, $localStorage) {
        var vm = this

        vm.$onInit = function () {
            vm.product = {}

            if ($stateParams.model) {
                vm.product.modelNo = $stateParams.model
            }

            vm.productTypes = $localStorage.catalogs.productTypes
            console.log('Add product component');
        }


        vm.save = function () {
            $ionicLoading.show()
            Product.create(vm.product)
                .then(response => {
                    ngNotify.success(response.data)
                    vm.product = {}

                    if ($stateParams.model) {
                        window.history.back();
                    }
                })
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

    }

})();
