(function () {
  'use strict';
  angular
    .module('app.auth.service',[])
      .factory('Auth',[
        '$http',
        'API',
        AuthService])

  function AuthService($http, API, $localStorage, FPConfig) {

    return {
      login:function (form) {
        var data = "grant_type=password&username=" + form.email + "&password=" + form.password;
        var headers = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };

        return $http.post(API + '/token', data, headers);
      },
      signup:function (data) {
        return $http({
          method:'POST',
          url: API + '/api/account/register',
          data:data
        });
      },
      updatePassword:function (passwords) {
        var endpoint = API + '/api/account/changepassword';
        return $http.put(endpoint, passwords);
      },
      // when something forgets his password
      password:function (email,location) {
        let endpoint = API + '/api/account/forgotpassword'
        return $http.put(endpoint,{email:email,location:location });
      },
      // add new device to the user's profile
      device:function (data) {
        let endpoint = API + '/api/account/devices'
        return $http.post(endpoint,data);
      },
      isLogged:function () {
        // we use this route because we already know it is restricted to logged users, we expect a 401 error if the current user is not logged, so this will trigger the interceptor and take us to the loginpage :)
        return $http.get(API + '/users/get')
      },
      verifyDeviceAccess:function (fingerprint) {        
        return $http.patch(API + '/api/account/device', { fingerprint: fingerprint })
      },
      getFingerPrint: function (devices) {                  
          return new Promise(function (resolve, reject) {              
              new Fingerprint2(FPConfig).get(function (myFingerPrint) {                  
                  resolve(myFingerPrint)
              });              
          })
      },
      deviceAuthorized: function (devices) {        
          // the fingerprint function is async so we need to wrap everything in a Promise
          // fortunately we are using babel on this project <3
          return new Promise(function (resolve, reject) {
              
              new Fingerprint2(FPConfig).get(function (myFingerPrint) {
                  let authorized = devices.find( d => d.fingerPrint == myFingerPrint )
                  let isAutorized = authorized ? true : false
                  resolve(isAutorized)

              });                            
          })
          
      }

    };


  }
})();
