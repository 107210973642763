(function () {
    'use strict';
    angular
        .module('app.quotes.service',[])
            .factory('Quote', function ($http, API) {

                return {
                    status: function () {
                        let endpoint = `${API}/api/quotes/`
                        return $http.patch(endpoint)
                    },
                    show: function (id) {
                        let endpoint = `${API}/api/quotes/${id}`
                        return $http.get(endpoint)
                    },
                    delete: function (id) {
                        let endpoint = `${API}/api/quotes/${id}`
                        return $http.delete(endpoint)
                    },
                    fetch: function () {
                        let endpoint = `${API}/api/quotes`
                        return $http.get(endpoint)
                    },
                    catalogs: function () {
                        let endpoint = `${API}/api/quotes/catalogs`
                        return $http.get(endpoint)
                    },                                                            
                    create: function (data) {
                        let endpoint = `${API}/api/quotes`
                        return $http.post(endpoint, data)
                    },
                    sendQuote: function (id, parameters) {
                        let endpoint = `${API}/api/quotes/${id}`
                        return $http.post(endpoint, parameters)
                    },
                    pdf: function (id, parameters) {
                        let endpoint = `${API}/api/quotes/${id}`
                        return $http.patch(endpoint, parameters)
                    },
                };
            });
})();
