(function () {
    'use strict';
    angular
        .module('app.admin.home',[])
            .config(function($stateProvider) {
                $stateProvider
                    .state('admin.home', {
                        url: "/home",
                        cache:false,
                        views: {
                              'menuContent': {
                                    template:`<ion-view title="Admin - Home"><admin-home></admin-home></ion-view>`
                              }
                        }
                        })
            })
            .component('adminHome',{
                templateUrl:'views/admin/admin-home.html',
                controller:AdminHomeCtrl
            })

    function AdminHomeCtrl (Admin, ngNotify, _, Quote, $ionicLoading, $ionicPopup) {
        var vm = this

        vm.$onInit = function () {
            console.log('admin home component');

            vm.active = 'users'
            
            Admin.users()
                .then(data => vm.users = data.data )
                .catch(ngNotify.danger)

            // get quotes from the last X days
            Admin.quotes()
                .then(data => vm.latestQuotes = _.reverse(data.data))
                .then(data => console.log(data))
                .catch(ngNotify.danger)
            
            getSystemStatus()
        }

        vm.toggleShutdown = function () {
            $ionicPopup.confirm({ title: 'Are you sure you want to toggle the system quoting status' })
                .then(ok => {
                    if (ok) {
                        $ionicLoading.show()
                        Admin.toggleSystemQuotingStatus(vm.enabledQuoting)
                            .then(ngNotify.success)
                            .catch(ngNotify.danger)
                            .finally(() => {
                                getSystemStatus()
                                $ionicLoading.hide()
                            })
                    }
                })

            

        }   

        function getSystemStatus () {
            Quote.status()
                .then( result => {
                    vm.enabledQuoting = result.data.enabledQuoting
                    console.log(result.data);
                })                
                .catch(ngNotify.danger)
        }

    }

})();
