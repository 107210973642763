(function () {
  'use strict';
  angular
    .module('app.components',[])

      .component('closeLoading',{
        bindings:{
          text:'='
        },
        template:`
          <ion-spinner></ion-spinner><br />
          {{$ctrl.text?$ctrl.text:'Loading...'}}
          <div ng-if="$ctrl.display" ng-click="$ctrl.close()">
            This request is taking too long <br>
            You can wait a little bit or cancel this process. <br>
            <a href="javascript:;">
              Close
            </a>
          </div>`,
        controller:'CloseLoadingCtrl'
      })
      .controller('CloseLoadingCtrl',['$ionicLoading','$timeout',function ($ionicLoading,$timeout) {
        var vm = this;
        vm.display = false;

        $timeout(function() {
            vm.display = true;
          }, 11000);

        vm.close = function () {
          $ionicLoading.hide();
        }
      }])
      .component('dashIcon',{
        bindings:{
          background:'@',
          color:'@',
          title:'@',
          icon:'@'
        },
        template:`
        <div
          style="text-align:center;
            background-color:{{$ctrl.color}};
            background-image:url({{$ctrl.background}});background-size: 100% 100%;">
          <div style="background: rgba(0,0,0,.3);padding:30px;width:100%;height:100%;overflow:auto;">
            <h3 style="">
              <i class="ion {{$ctrl.icon}}" style="font-size:50px;color:#fff!important"></i></h3>
            <h4 style="color:#fff!important">{{$ctrl.title}}</h4>
          </div>
        </div>`
      })



      .component('downloadFile', {
          template: `
          <button class="button button-assertive button-outline button-small" style="width:48%"
            ng-click="$ctrl.download()" ng-disabled="!$ctrl.file">
            {{ $ctrl.title || 'Download' }}
          </button>`,
          bindings: {
            file: '=',
            title: '@'
          },
          controller: Ctrl
      })
      
      function Ctrl (File, $ionicLoading) {
          var vm = this
          
          vm.download = function () {
              if (!vm.file) return ;
              $ionicLoading.show()
              console.log(vm.file);
              File.download(vm.file)
                  .then(result => {
                      var downloadedFile = new Blob([result.data], { type: 'application/pdf' });
                      saveAs(downloadedFile, _.trim(vm.file));
                  })
                  .catch(err => {
                      if (err.status == 402) {
                          window.alert('You might need to wait a few minutes before downloading more files.')
                      } else {
                          window.alert('Error downloading the file')
                      }
                      
                      console.error(err)
                  })
                  .finally($ionicLoading.hide) 
          }
      }

})();
