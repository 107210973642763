(function () {
    'use strict';

    angular
        .module('app.quotes.heat-pump',[])
            .config(SystemConfig)
            .component('heatPump',{
                templateUrl: 'views/complete-systems/heat-pump.html',
                controller: ComponentCtrl
            })

    function ComponentCtrl (Quote, ngNotify, $ionicLoading, QuoteFilters, Cart, System, Helper, $stateParams) {


        var vm = this

        vm.filtered = false

        console.log($stateParams.selected);

        vm.$onInit = function () {

            // forms
            vm.form = {};
            // the idea is to store in this object the selected items for each subcategory
            vm.selected = {}

            // initialize ranges
            QuoteFilters.resetRanges(vm)

            // load combinations table
            System.productsFromUnitType(3)
                .then(result => {                    
                    vm.all = result.data.map(System.parseProperties)
                    vm.filtered = vm.all
                    console.log(vm.all[0])

                    if ($stateParams && $stateParams.selected) {
                        console.log($stateParams.selected);
                        let filterableFields = [
                            'heatPumpBrand',
                            'condenserTonnage',
                            'heatPumpStages',
                            'voltage',
                            'hspf',
                            'heatPumpModelNo',
                            'airHandlerBrand',
                            'airHandlerTonnage',
                            'airHandlerCoolStages',
                            'airHandlerModelNo',                            
                        ]

                        filterableFields.forEach(field => vm.form[field] = $stateParams.selected[field])
                        vm.selected = $stateParams.selected
                        vm.filterOut(vm);
                    }

                    groupResults()
                })
                .catch(ngNotify.danger)
        }

        // add selected item to the shopping cart
        vm.addToCart = Cart.add

        // remove items from vm.filters list based on the user form selections
        vm.filterOut = function (ctrl) {
            QuoteFilters.filterOut(ctrl)

            // show only possible answers for the unchecked fields
            groupResults()
        }

        // reset all the filters already set
        vm.resetFilters = function (ctrl) {
            QuoteFilters.resetFilters(ctrl)            
            groupResults()
        }

        // clear EER or SEER range
        vm.resetRange = function (range) {
            vm[range] = {}            
            vm.filterOut(vm)
            groupResults()
        }

        // reset a single filter
        vm.resetField = function (field, ctrl) {
            QuoteFilters.resetField(field, ctrl)            
            // show only possible answers for the unchecked fields
            groupResults()
        }

        vm.select = function (item) {             
            vm.selected = item             
            vm.selected.models = System.getModels(item)
            console.log(vm.selected);
        }

        // group columns for populating the dropboxes
        function groupResults() {

            let fields = [
                'eer',
                'seer',
                'voltage',
                'condenserTonnage',
                'heatPumpStages',
                'airHandlerBrand',
                'airHandlerTonnage',
                'airHandlerCoolStages',
                'airHandlerModelNo',
                'heatPumpModelNo',
                'hspf',
                'heatPumpBrand'
                
                /*
                Former table schema
                'airHandlerBrandId',                
                'airHandlerStageId',                
                'heatPumpStageId',                                
                */
            ]

            // array to populate form select box for filtering the results
            vm.dropdown = {}

            for (let item in fields) {
                let field = fields[item]
                vm.dropdown[field] = Object.keys(_.groupBy(vm.filtered, field));

                vm.dropdown[field] = vm.dropdown[field].sort(Helper.naturalSort)
            }


        }

    }


    function SystemConfig ($stateProvider) {
        $stateProvider
            .state('app.heat-pump', {
                url: '/heat-pump',
                cache:false,
                params:{
                    selected: null
                },
                views: {
                      'menuContent': { template:`<ion-view title="Build Heat Pump System"><heat-pump></heat-pump></ion-view>` }
                }
            })
    }
})();
