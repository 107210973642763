(function () {
    'use strict';
    angular
        .module('app.products.create-group', [])
            .component('createGroup', {
                template: `
                <button
                    class="button button-positive button-outline button-small"
                    ng-click="$ctrl.addGroup()">
                    Create group
                </button>`,
                controller: Ctrl
            })

    function Ctrl (Product, $state, $ionicPopup, ngNotify, ionicToast, $stateParams) {
        var vm = this

        vm.$onInit = function () {
            console.log('Create group component');

        }

        vm.addGroup = function () {

            $ionicPopup.prompt({
                title: 'Group Name:',
                inputText: 'Type here',
            })
            .then(res => {
                if (!res) {
                    return ;
                }
                let group = {
                    name: res
                }

                Product.addGroup(group)
                    .then(result => {
                        console.log(result);
                        ionicToast.show('Group added successfully.', 'middle');

                        if ($stateParams && $stateParams.id) {
                            // TODO: add a multiplier using the created group to the user profile
                        }

                        $state.go('admin.group', { id: result.data, products: vm.products })
                    })
                    .catch(ngNotify.danger)
            })
        }
    }
})();
