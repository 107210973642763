(function () {
    'use strict';
    angular
        .module('app.admin.pricing',[])
            .config(Config)
            .component('pricingList',{
                templateUrl: 'views/admin-pricing/admin-pricing.html',
                controller: ComponentCtrl
            })

    function ComponentCtrl ( ngNotify, $ionicLoading, $localStorage, Product, $ionicPopup, $scope, ionicToast, $state, $stateParams) {

        var vm = this

        vm.$onInit = function () {

            // main tab active by default
            vm.active = $stateParams.model ? 'add-product':'models' 

            vm.productTypes = $localStorage.catalogs.productTypes
            vm.errors = []

            vm.toggleSelectionCheckbox = false

            vm.productTypes = $localStorage.catalogs.productTypes

            vm.pricingExample = [
                { modelNo:'XXX', description: 'XXX', vendor: 123, price: 100 },
                { modelNo:'XXX', description: 'XXX', vendor: 123, price: 100 }
            ]

            getProducts()
            getGroups()

        }

        vm.countModified = () => {
            let selectedProducts = vm.products.filter( p => p.selected);
            vm.selectedProducts = selectedProducts.length
            console.log(vm.selectedProducts);
        }

        


        vm.reload = function () {
            getProducts()
            getGroups()
        }

        vm.remove = function (item) {

            var confirmPopup = $ionicPopup.confirm({
                title: 'Delete Item',
                template: 'Are you sure you want to delete this item?'
            });

            confirmPopup.then(function(res) {
                if(res) {
                    $ionicLoading.show()
                    Product.remove(item.id)
                        .then(ngNotify.success)
                        .then(() => {
                            // delete from table
                            let elem = document.getElementById('m-' + item.id);
                            return elem.parentNode.removeChild(elem);
                        })
                        .catch(ngNotify.danger)
                        .finally($ionicLoading.hide)
                }
            });

        }
        

        vm.removeBatch = function (arr, index) {

            let selectedProducts = vm.products.filter( p => p.selected);
            console.log(selectedProducts);
            
            let modelsToDelete = ''

            selectedProducts.forEach(p => modelsToDelete += p.modelNo + ', ' )
            
            console.log('Deleting multiple products');
            var confirmPopup = $ionicPopup.confirm({
                title: 'Delete Item',
                template: 'Are you sure you want to delete these products? <br>' +  modelsToDelete
            });

            confirmPopup.then(function(res) {
                if(res) {
                    
                    let promises = selectedProducts.map(p => Product.remove(p.id)                        
                        .then(() => {                            
                            let elem = document.getElementById('m-' + p.id);
                            return elem.parentNode.removeChild(elem);
                        })
                    )

                    if (promises.length === 0) return ;

                    $ionicLoading.show()
                    Promise.all(promises)
                        .then(() => {
                            $ionicLoading.hide()
                            ngNotify.success('Products deleted successfully')
                        })
                        .catch(ngNotify.danger)                        
                                            
                }
            });
        }

        vm.addGroup = function () {
            let selectedProducts = vm.products.filter( p => p.selected);
            console.log(selectedProducts);
            vm.progress = 0

            selectedProducts = selectedProducts.map(function (p) {
                let p2 = { productId: p.id }
                return p2;
            })


            $ionicPopup.prompt({
                title: 'Group Name:',
                inputText: 'Type here',
            })
            .then(res => {
                if (!res) {
                    return ;
                }
                let group = {
                    name: res,
                    productList: selectedProducts
                }

                Product.addGroup(group)
                    .then(result => {
                        console.log(result);
                        ionicToast.show('Group added successfully.', 'middle');
                        $state.go('admin.group', { id: result.data, products: vm.products })
                    })
                    .catch(ngNotify.danger)
            })

        }

        vm.openGroup = function (id) {
            console.log(vm.products);
            $state.go('admin.group', { id: id, products: vm.products })
        }

        vm.updatePrice = product => {            
            product.originalPrice = product.price
            Product.updatePrice(product)
                .then(result => {
                    ionicToast.show(result.data)
                    
                })
                .catch(ngNotify.danger)

        }

        vm.updateBatch = function () {
            let selectedProducts = vm.products.filter( p => p.selected);
            console.log(selectedProducts);
            
            let modelsToDelete = ''

            selectedProducts.forEach(p => modelsToDelete += p.modelNo + ', ' )
            
            console.log('Deleting multiple products');
            let newPrice = prompt('Type the new price for all the selected products:')

            if (newPrice) {
                selectedProducts.forEach(p => p.price = parseFloat(newPrice))
                let promises = selectedProducts.map(Product.updatePrice)

                if (promises.length === 0) return ;

                $ionicLoading.show()
                Promise.all(promises)
                    .then(() => {
                        $ionicLoading.hide()
                        ngNotify.success('Products deleted successfully')
                    })
                    .catch(ngNotify.danger)                        
            }

        }

        vm.deleteGroup = function (group) {
            var confirmPopup = $ionicPopup.confirm({
                title: 'Delete Group',
                template: 'Are you sure you want to delete this group?'
            });

            confirmPopup.then( confirmed => {
                if (confirmed) {
                    $ionicLoading.show()
                    Product.deleteGroup(group.id)
                        .then(res => {
                            ionicToast.show(res.data)
                            getGroups()
                        })
                        .catch(ionicToast.show)
                        .finally($ionicLoading.hide)
                }
            })
        }

        vm.toggleSelection = function (prods) {
            prods.forEach( pr => pr.selected = vm.toggleSelectionCheckbox )
            vm.countModified()
        }

     
      
        

        function getProducts () {            

            Product.all()
                .then(result => {
                    let data = result.data
                    // console.log(data[0]);
                    let cats = data.map(d => d.category)
                    vm.categories = _.uniq(cats, 'category')
                    vm.categories = vm.categories.sort()
                    // console.log(vm.categories)
                    vm.products = data
                    $scope.$broadcast('scroll.refreshComplete');

                })
                .catch(ngNotify.danger)                
        }

        function getGroups () {
            Product.groups()
                .then(result => vm.groups = result.data)
                .catch(ngNotify.danger)
        }

    }


    function Config ($stateProvider) {

        $stateProvider
            .state('admin.pricing', {
                url: "/pricing?model",
                cache:false,
                views: {
                      'menuContent': {
                            template:`
                            <ion-view title="Admin - Pricing">
                                <pricing-list></pricing-list>
                            </ion-view>`
                      }
                }
        })
    }

})();
