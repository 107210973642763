(function () {
  'use strict';
  angular
    .module('app.auth.login',[])
      .component('authLogin',{
        template:`
        <div class="list" style="background:rgba(255,255,255,.10)!important;">
          <label class="item item-input" style="background:rgba(255,255,255,.0)!important;">
            <input type="email"
              id="email"
              style="background:rgba(255,255,255,.0)!important;"
              placeholder="Email"
              ng-model="$ctrl.user.email" required>
          </label>
          <label class="item item-input" show-hide-container style="background:rgba(255,255,255,.0)!important;">
            <input type="password"
              id="user-password" 
              style="background:rgba(255,255,255,.0)!important;width:100%"
              placeholder="Password"
              ng-model="$ctrl.user.password" required show-hide-input>
          </label>
        </div>
        <ion-list ng-if="$ctrl.user.password && $ctrl.user.email && $ctrl.user.password.length>5" style="background:rgba(255,255,255,.0)!important;">
            <ion-radio ng-model="$ctrl.user.displayMarkup" ng-value="false" style="background:rgba(255,255,255,.0)!important;">
                Quoting Mode
            </ion-radio>
            <ion-radio ng-model="$ctrl.user.displayMarkup" ng-value="true">
                Sales Mode
            </ion-radio>
        </ion-list>
        <br>
        <div class="item item-body bottom-content" ng-if="$ctrl.user.password && $ctrl.user.email && $ctrl.user.password.length>5" style="background:rgba(255,255,255,.0)!important;">
          <button type="submit"
            class="button button-assertive button-block"
            ng-click="$ctrl.login()">
            Log In
          </button>
        </div>`,
        controller:'AuthLoginCtrl'
      })
      .controller('AuthLoginCtrl',function (Auth, $localStorage ,$stateParams, $state, $ionicLoading, $rootScope,ngNotify, User, Idle, FPConfig) {

        console.log('Login component')

        var vm = this;

        vm.user = {
            navigator: window.navigator.userAgent,
            displayMarkup: false,
            email: null
        };

        vm.user.email = null
        // we have to log out the user to avoid conflicts trying to log in when we already have an expired token
        $localStorage.token = null;
        $localStorage.user = null;

        window.superplaceholder({
            el: document.querySelector('#email'),
            sentences: ['Enter Your Email']
        });

        // let's check if this device is authorized to use the system based on the browser fingerprint
        new window.Fingerprint2(FPConfig).get( result => vm.fingerPrint = result );

        document.getElementById("email").focus();

        vm.login = function () {
            console.log('Loggin in...')
            $ionicLoading.show({
                // template: '<close-loading text=\"Logging in\"></close-loading>'
            });

          Auth.login(vm.user)
            .then(result => {

              // yei! user is authenticated, we can go derping around now
              console.log('User logged successfully')

              Idle.watch()

              $localStorage.token = result.data.access_token;
              return User.profile();
            })
            .then(result => {

                $localStorage.cart = JSON.parse(result.data.quotingCart || '[]' )
                $rootScope.$broadcast('updateCart')
                

                // delete property to avoid having it duplicated
                delete result.data.quotingCart
                $localStorage.user = result.data

                

                // fiiiiirst of all, check if the authorization was granted
                console.log(`granted access: ${$localStorage.user.isActive}`);
                if ($localStorage.user && $localStorage.user.isActive === false ) {
                    return $state.go('auth.pending')
                }

                // update face component values
                $rootScope.$broadcast('updateProfile')

                // set selected mode
                $localStorage.displayMarkup = vm.user.displayMarkup

                let devices = $localStorage.user.userDevices;
                // check if user is an admin
                console.log($localStorage.user.userRoles);
                
                let isAdmin = $localStorage.user.userRoles.find( r => r.roleId === 1)
                if (isAdmin) {
                    console.log('Administrator access');
                    return $state.go('admin.home')
                }

                let isTerritoryManager = $localStorage.user.userRoles.find( r => r.roleId === 3)
                if (isTerritoryManager) {
                    console.log('Territory Manager access');
                    return $state.go('app.managers')
                }


                // we should move this check to the backend
                let fingerprintAuthorized = devices
                    .find( d => d.fingerPrint === vm.fingerPrint && d.approved === true )


                if (fingerprintAuthorized) {
                    console.log('device correct');
                    console.log(result.data);
                    return $state.go('app.home')
                } else {
                    console.log('different device');
                    return $state.go('auth.device')
                }

            })
            .catch(err => {                
                console.log('Login failure :C')
                console.error(err.data || err)
                if (err && err.data && err.data.error_description) {
                    $localStorage.user = null;
                    $localStorage.token = null;
                    return ngNotify.error(err.data.error_description);
                } 

                if (err && err.data) {
                     let errMessage =  err.data;
                    return ngNotify.error(errMessage)
                }

                ngNotify.error('Something went wrong, please try again later')                

               
            })
            .finally($ionicLoading.hide)
        }

      });
})();
