(function () {
    'use strict';

    angular
        .module('app.admin.button', [])
            .component('adminButton',{
                template: `                
                    <button class="button {{$ctrl.class}} button-xs" 
                        ng-if="$ctrl.isAdmin"                         
                        ui-sref="{{$ctrl.state}}($ctrl.params)">{{$ctrl.text}}</button>`,
                controller:ComponentCtrl,
                bindings: {
                    state: '@',
                    params: '=',
                    text: '@',
                    class: '@'
                }
            })

    function ComponentCtrl ($localStorage) {

        var vm = this        

        vm.$onInit = function () {
            
            // verify admin permissions
            if ($localStorage.user && $localStorage.user.userRoles) {
                let isAdmin = $localStorage.user.userRoles.find( role => role.roleId === 1   )
                
                vm.isAdmin = isAdmin && isAdmin.roleId && isAdmin.roleId === 1 ? true : false;                
            }

        }


    }

})();
