(function () {
  'use strict';

  angular
    .module('app.auth.pending', [])
        .config(function ($stateProvider) {
            $stateProvider
                .state('auth.pending', {
                    url: '/pending',
                    templateUrl:'views/auth/views/pending.html',
                    controller:'PendingCtrl',
                    controllerAs:'$ctrl'
                })
        })
        .controller('PendingCtrl',function (User, $state, $localStorage, $ionicLoading, Auth, ngNotify, FPConfig) {
            var vm = this

            console.log('Pending component');
            var fp = new Fingerprint2(FPConfig)

            var device;
            
                        


            vm.getProfile = function () {
                $ionicLoading.show();
                User.profile()
                    .then(function (data) {
                        vm.profile = data.data
                        console.log(vm.profile)

                        // check if this device is on the customer authorized devices
                        return Auth.verifyDeviceAccess(device)                    

                    })
                    .then(approved => {
                        console.log(approved);
                        if (approved && vm.profile.isActive) {
                            console.log('Authorization already was granted for this device')
                            $state.go('app.home')
                        }
                    })
                    .catch(err => {
                        console.log(err.data);
                        if(err && err.data && err.data.message && err.data.message ==='Unauthorized device') {
                            vm.requestSent = false
                        } else  if(err && err.data && err.data.message && err.data.message ==='Device already submitted, but not approved yet') {
                            vm.requestSent = true
                        } else {
                            ngNotify.danger('Something went wrong, please try again later.')
                        }

                    })
                   
                    .finally($ionicLoading.hide)
            }

            
            fp.get( (result, components) => {
                device = result
                console.log(device);
                console.log(result);
                vm.getProfile()
            });


            

            
        })
})();
