(function () {
    'use strict';

    angular
        .module('app.files', [
            'app.files.manager',
            'app.files.service',
            'app.files.ahri',
            'app.files.brochure',
            'app.files.submittal',
            'app.files.installation-manual'
        ])
})();
