(function () {
  'use strict';
  /*
  Rutas de la aplicación
  */
  angular
    .module('app.auth.routes',[])
      .config(['$stateProvider',function($stateProvider) {

        $stateProvider

        //INTRO
        .state('auth', {
          url: "/auth",
          templateUrl: "views/auth/views/auth.html",
          controller: 'AppCtrl',
          controllerAs:'$ctrl',
          abstract: true
        })

        .state('auth.walkthrough', {
          url: '/walkthrough',
          templateUrl: "views/auth/views/walkthrough.html"
        })
        .state('auth.start', {
          url: '/start',
          template:'<auth-start></auth-start>',
        })
        .state('auth.config', {
          url: '/config',
          template:'<auth-config></auth-config>',
        })
        .state('auth.login', {
          url: '/login?backTo',
          templateUrl:'views/auth/views/login.html'
        })        
        .state('auth.logout', {
          url: '/logout',
          cache:false,
          controller: 'LogOutCtrl'
        })

        .state('auth.forgot-password', {
          url: "/forgot-password",
          templateUrl: "views/auth/views/forgot-password.html",
        })

      }]);

})();
