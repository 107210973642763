(function () {

    // NOTE: we are only displaying products having price set up, if price is null we will not see it on the backend response

    angular
        .module('app.product-selection', [])
            .config(Config)
            .component('productSelection', {
                templateUrl: 'views/product-selection/product-selection.html',
                controller: Ctrl
            })

    function Ctrl (Product, System, $stateParams, $ionicLoading, ngNotify, $localStorage, _, Cart, $timeout, Helper) {

        var vm = this
        window.ctrl = vm;

        vm.productTypeId = $stateParams.id
    

        vm.$onInit = function () {
            vm.form = {}
            console.log('Product selection');

            // if we are under the minisplit categories we will display two tabs to switch between indoor and outdoor
            // more explaining: there are two minisplit categories, but we wan't them to act as a single one from the user's perspective BUT as two different from the technical side
             if (vm.productTypeId == 7 || vm.productTypeId == 9 ) vm.miniSplit = true

            // fields to be ignored when editing a product from the shoppincart
            // the idea is to avoid having filters affecting the possible matches that can't be cleaned from the UI
            vm.hiddenFields = ['description','vendor','productTypeId','eer','modelNo']

            // we don't want to affect the original productTypes catalog... so we clone it!
            let productTypes = _.clone($localStorage.catalogs.productTypes)
            
            // remove minisplit- outdoor product type in order to avoid having two minisplits
            productTypes = productTypes.filter(pt => pt.id != 9)

            // rename "MiniSplit - Outdoor" to simply "MiniSplit" to avoid missunderstandings
            // see notes on the product selection component for more info
            productTypes.forEach(pt => pt.name.indexOf('utdoor')>-1 ? pt.name = 'Mini Split': '' )

            
            // divide array in groups of two in order to display two columns on the view            
            vm.rows = _.chunk(productTypes,2)

            if (vm.productTypeId) {
                getProducts(vm.productTypeId)
            }
        }

        vm.filterOut = function () {            
            vm.filtered = vm.products;

            console.log(vm.form);

            Object.keys(vm.form).forEach(function (key) {
                if (vm.form[key] === 'Choose option') delete vm.form[key]
                // each dropdown selection is represented as an attribute on this object: vm.form
                // vm.filtered = possible matches, so we keep only values that match the dropdown selection
                if (vm.form[key]) {
                    let selectedValue = String(vm.form[key]).toUpperCase()

                    vm.filtered = vm.filtered.filter(f => String(f[key]).toUpperCase() === selectedValue)
                }
            });

            groupResults()
        }

        vm.resetField = function (field) {
            delete vm.form[field]
            vm.filterOut()
            groupResults()
        }

        vm.resetFilters = function () {
            vm.selected = null
            vm.form = {}
            vm.filterOut()
            groupResults();
        }

        vm.select = function (selected) {
            vm.selected = null

            // kind of a $scope.$apply to solve a bug that was causing the product box to not update
            $timeout(()=>{
                selected.productTypeId = $stateParams.id
                vm.selected = selected
            }, 100)

        }

        // add selected item to the shopping cart
        vm.addToCart = Cart.add

        function getProducts (productTypeId) {

            let category = $localStorage.catalogs.productTypes.find(pt => pt.id == productTypeId)
            vm.category = category.name

            $ionicLoading.show()
            Product.getByTypeId(productTypeId)
                .then( data => {

                    // let productsWithSpecs = data.data.filter(p => p.specs != null)
                    vm.products = data.data.map(Product.parseSpecs)
                    vm.products = vm.products.filter(prod => prod.description != null)
                    vm.filtered = vm.products

                    // vm.exampleProd = vm.products.find(p => Object.keys(p).length > 4)
                    vm.exampleProd = vm.products[0]
                    
                    if ($stateParams && $stateParams.selected) {
                        let editParams = $stateParams.selected                                                

                        let filterableFields = []
                        _.each(editParams, (value, key)=> {
                            if (vm.hiddenFields.indexOf(key)===-1){
                                filterableFields.push(key)
                            }                            
                        })                        
                        
                        filterableFields.forEach(field => vm.form[field] = editParams[field])

                        vm.selected = editParams
                        vm.filterOut()   

                        if (vm.products.length < 40) console.log(vm.products)
                    }

                    groupResults()
                })
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }


        // group columns for populating the dropboxes
        function groupResults() {

            if (!vm.exampleProd) ngNotify.error('Empty category')

            let fields = Object.keys(vm.exampleProd)

            console.log(fields)
            // array to populate form select box for filtering the results
            vm.dropdown = {}

            for (let item in fields) {
                let field = fields[item]                
                vm.dropdown[field] = Object.keys(_.groupBy(vm.filtered, field))                
                vm.dropdown[field] = vm.dropdown[field].filter(o => o!='undefined')
                
                vm.dropdown[field] = vm.dropdown[field].sort(Helper.naturalSort)
                
            }

            console.log(vm.dropdown);

        }


    }

    function Config ($stateProvider) {
        $stateProvider
            .state('app.product-selection', {
                url: "/product-selection?id",
                cache:false,
                params:{
                    selected: null
                },
                views: {
                      'menuContent': {
                            template:`<ion-view title="Product Selection">
                            <product-selection></product-selection></ion-view>`
                      }
                  }
              })
    }

})();
