(function () {
    'use strict';
    angular
        .module('app.admin.import-products',[])
            .config(Config)
            .component('importProducts',{
                templateUrl: 'views/admin-pricing/import-products.html',
                controller: ComponentCtrl
            })

    function ComponentCtrl ( ngNotify, $ionicLoading, $localStorage, Product, $ionicPopup, $scope, ionicToast, $state, $stateParams) {

        var vm = this

        vm.$onInit = function () {

            // main tab active by default
            vm.active = $stateParams.model ? 'add-product':'models' 

            vm.productTypes = $localStorage.catalogs.productTypes
            vm.errors = []

            vm.toggleSelectionCheckbox = false

            vm.productTypes = $localStorage.catalogs.productTypes

            console.log(vm.productTypes);

            vm.pricingExample = [
                { modelNo:'XXX', description: 'XXX', vendor: 123, price: 100 },
                { modelNo:'XXX', description: 'XXX', vendor: 123, price: 100 }
            ]
            

        }

        vm.importProducts = function () {

            vm.importedProducts = 0
            vm.productsToImport.map(createProduct)

            function createProduct (prod) {
                Product.create(prod)
                    .then(() => {
                        vm.importedProducts += 1
                        if (vm.importedProducts >= vm.productsToImport.length) {
                            ionicToast.show('Products imported successfully.', 'middle');                         
                            // reset table to remove "import" button
                            vm.modelTable = null
                            vm.importedProducts = 0
                        }
                    })
                    .catch(err => {
                        ionicToast.show('Error importing one of the systems.', 'middle');
                        console.error(err)
                    })
            }

        }
        

        vm.importSpecs = function () {
            vm.importedProducts = 0
            vm.productsToImport = vm.productsToImport.filter(p => p.ModelNo.indexOf('Model')==-1)
            vm.productsToImport.map(updateSpecs)

            vm.updated = 0;
            vm.created = 0;

            function updateSpecs (prod) {                
                Product.addSpecs(prod)
                    .then(result => {
                        console.log(prod);
                        vm.importedProducts += 1
                        if (vm.importedProducts >= vm.productsToImport.length) {
                            ionicToast.show('Products imported successfully.', 'middle');             
                        }
                        result.data.indexOf('updated')>-1 ? vm.updated++:vm.created++;
                    })
                    .catch(err => {
                        // ionicToast.show('Error importing one of the systems.', 'middle');
                        console.error(err.data)
                        vm.errors.push(err.data)
                    })
            }
        }
    

        // this approach uses column order
        vm.parseTable = function (data) {            
            // reset table
            vm.productsToImport = []
            let rows = data.split('\n')
            // let table = rows.map(r => r.split('\t'))
            rows.forEach(row => {
                let cols = row.split('\t')
                let prod = {
                    modelNo: cols[0],
                    description: cols[1],
                    vendor: cols[2],
                    price: cols[3],                        
                }
                if (prod.modelNo.indexOf('Model')===-1) {
                    vm.productsToImport.push(prod)
                }
            })                

        }

        // this approach uses string matching to try to find correspondig fields
        vm.parseTableByColumnName = function (data) {
            try {
                // reset table
                vm.productsToImport = []
                
                let rows = data.split('\n')     

                // detext headers row, make it an array and
                let columNames = rows
                    .find( d => d.indexOf('Unit Type')>-1)
                    .split('\t')
                    .map( c => c = _.pascalCase(c) )  

                console.log(columNames)

                // delete first element of array, since it's the column names
                rows.shift()
                

                // TODO: this is NOT working yet
                rows.forEach(row => {
                    let cols = row.split('\t')
                                    
                    let prod = {}
                    cols.forEach((val,key) => prod[columNames[key]] = _.trimIfString(val) )   
                    
                    
                    _.each(prod, function (val, key) {
                        let insertProd = {}
                    })
                    
                    // remove the first letter since we don't know if it will be upper or lower case
                    if (columNames[key].indexOf('odelNo')) prod.modelNo = _.trimIfString(val)            
                    if (columNames[key].indexOf('escription')) prod.description = _.trimIfString(val)
                    if (columNames[key].indexOf('endor')) prod.vendor = _.trimIfString(val)
                    if (columNames[key].indexOf('rice')) prod.price = _.trimIfString(val)
                        
                    if (vm.importProductTypeId) prod.productTypeId = vm.importProductTypeId

                    vm.productsToImport.push(prod)

                })     
                // TODO: this is NOT working yet           
                

                console.log(vm.productsToImport)

            } catch (err) {
                ngNotify.error('Ops! Format error, we cannot import the pasted data')
                vm.modelTable = null
                console.error(err);
            }
        }

        vm.parseSpecsTable = function (data) {

            try {                

                let productTypeFields = vm.productTypes.find(pt => pt.id == vm.importProductTypeId)
                // productTypeFields = JSON.parse(productTypeFields.specs)                
                
                // reset table
                vm.productsToImport = []
                let rows = data.split('\n')     

                // detext headers row, make it an array and
                let columNames = rows
                    .find( d => d.indexOf('Unit Type')>-1 || d.indexOf('Model')>-1)
                    .split('\t')
                    .map( c => c = _.pascalCase(c) )           

                // create object with the column names as properties
                rows.forEach(row => {
                    let cols = row.split('\t')
                                    
                    let prod = {}
                    
                    cols.forEach((val,key) => prod[columNames[key]] = _.trimIfString(val) )

                    // detect product model number and delete it from the specs
                    let ModelNo
                    _.each(prod, (val, key) => {
                        if (key.indexOf('odelNo')>-1) {
                            ModelNo = val
                            delete prod[key]                            
                        }                        
                    })
                    // delete unit type string, since we will use its ID instead, except for unit types where we need to know if we have a gas electric or a heat pump
                    if( vm.importProductTypeId !== 8) {
                        delete prod['UnitType']                        
                    }

                    // stringify all the other properties
                    let jsonObject = {}
                    _.each(prod, (val, key) => jsonObject[key] = val  )
                                                            

                    
                    vm.productsToImport.push({
                        ModelNo: ModelNo,
                        Specs: JSON.stringify(jsonObject),
                        ProductTypeId: vm.importProductTypeId
                    })                    
                })
       
            } catch (err) {
                console.error(err)
            }
            

        }
        

    }


    function Config ($stateProvider) {

        $stateProvider
            .state('admin.import-products', {
                url: "/import-products",
                cache:false,
                views: {
                      'menuContent': {
                            template:`
                            <ion-view title="Admin - Import Products">
                                <import-products></import-products>
                            </ion-view>`
                      }
                }
        })
    }

})();
