(function () {
    'use strict';
    angular
        .module('app.auth.signup',[])
        .config(function ($stateProvider) {
            $stateProvider
                .state('auth.signup', {
                    url: '/signup',
                    // templateUrl:'views/auth/views/signup.html'
                    template:'<auth-signup></auth-signup>'
                })
        })
      .component('authSignup',{
          templateUrl:'views/auth/views/signup.html',
          controller:'AuthSignUpCtrl'
      })
      .controller('AuthSignUpCtrl',AuthSignUpCtrl);

    function AuthSignUpCtrl (Auth, $localStorage, $state, $ionicLoading, ngNotify, User, $rootScope, _, Idle, FPConfig) {

        console.log('Signup component')

        var vm = this
        vm.formErrors = []

        vm.step = 1

        // get territory managers
        User.managers()
            .then(result => vm.managers = result.data)
            .catch(ngNotify.danger)


        new Fingerprint2(FPConfig)
        .get(function(result, components){
            vm.user.fingerprint = result;
            vm.user.device = window.platform.description
            // console.log(result); //a hash, representing your device fingerprint
            // console.log(components); // an array of FP components
        });

        // add the site configuration defined by the admin
        vm.user = {};

        // we have to log out the user to avoid conflicts trying to log in when we already have an expired token
        $localStorage.token = null
        $localStorage.user = null

        vm.signup = function () {

            // restart all errors
            vm.formErrors = []

            if (vm.user.password !== vm.user.confirmPassword) {
                vm.user.password = null
                vm.user.confirmPassword = null
                return alert('Passwords don\'t match')
            }

            console.log('Signing up...')
            console.log(vm.user)

            $ionicLoading.show();


            Auth.signup(vm.user)
            .then(result => {
                // yei! user is authenticated, we can go derping around now
                console.log('Account created successfully')
                console.log(result)
                // we log the registered user in
                return Auth.login(vm.user)
            })
            .then(result => {
                $localStorage.token = result.data.access_token;
                // log out user if AFK
                Idle.watch()
                // get the user information
                return User.profile()
            })
            .then(result => {

                $localStorage.user = result.data;
                // send a message to other controllers to tell them to update the user's profile
                $rootScope.$broadcast('updateProfile')
                return $state.go('auth.pending')

            })
            .catch(err => {
              console.error(err);
              if (err && err.data && err.data.error_description) {
                ngNotify.error(err.data.error_description)
              }

              if (err && err.data && err.data.message) {
                ngNotify.error(err.data.message)
              }

              if (err && err.data && err.data.modelState) {
                  let formErrors = err.data.modelState
                  // add the description of all the errors in the password
                  if (formErrors && formErrors['userModel.Password']) {
                    formErrors['userModel.Password'].map(perr => vm.formErrors.push(perr))
                  }
                  // Detect errors... sniff, the returned model state doesn't have an ideal structure
                  _.each(formErrors,value => {
                    console.error(value[0])
                    ngNotify.error(value[0])
                  })

                  console.log(JSON.stringify(err))
              }


            })
            .finally($ionicLoading.hide)
        }


      }
})();
