(function () {
  'use strict';
  angular
    .module('app.settings.password',[])
      .component('settingsPassword',{
        template:`
        <ul class="list">
          <label class="item item-input item-stacked-label">
            <span class="input-label">Set new password</span>
            <input type="password" ng-model="$ctrl.user.newPassword" >
          </label>
          <label class="item item-input item-stacked-label">
            <span class="input-label">Type password again</span>
            <input type="password" ng-model="$ctrl.user.newPasswordRetype">
          </label>
          <label class="item item-input item-stacked-label">
            <button class="button button-calm button-block"
              ng-click="$ctrl.update()">
              <i class="icon ion-checkmark" style="color:#fff"></i>
              Update password
            </button>
          </label>
        </ul>`,
        controller:'ChangePasswordCtrl'
      })
      .controller('ChangePasswordCtrl',function (Auth,ngNotify,$stateParams,$ionicLoading) {

        var vm = this;



        vm.update = function () {

          $ionicLoading.show({
      			template: '<close-loading text="Updating password"></close-loading>'
      		});

          var isInvalid = vm.user.newPassword === undefined || vm.user.newPassword === "";
          isInvalid = isInvalid || vm.user.newPasswordRetype === undefined || vm.user.newPasswordRetype === "";

          var passwordsDontMatch = vm.user.newPassword !== vm.user.newPasswordRetype

          if (isInvalid) {
              vm.message = "Please enter the required values!";
          }
          else if (passwordsDontMatch) {
              vm.message = "Passwords don't match!";
          }
          else {

              var info = {
                  userId: vm.user.id,
                  password: vm.user.newPassword
              }

              Auth.resetPassword(info)
                .then(function (result) {
                      vm.user.newPassword = "";
                      vm.user.newPasswordRetype = "";
                      ngNotify.success(result.data)
                  })
                .catch(function (err) {
                  var error = err.exceptionMessage || err.message || "There was an error on updating";
                  ngNotify.error(error);
                })
                .finally( () => $ionicLoading.hide() );
          }
        }


      })
})();
