(function () {
  'use strict';
  angular
    .module('app.auth.device',[])
        .config(function ($stateProvider) {
            $stateProvider
                .state('auth.device', {
                    url: '/device',
                    templateUrl:'views/auth/views/device-change.html',
                    controller:'DeviceCtrl',
                    controllerAs:'$ctrl'
                })
        })
        .controller('DeviceCtrl', function (User, $state, ngNotify, $ionicLoading, Auth, $localStorage, FPConfig) {
            var vm = this
            console.log('device component');

            new Fingerprint2(FPConfig).get( (result, components) => {
                console.log(components);
                vm.fingerprint = result                

                vm.deviceAuthorized()
            });
            

            vm.deviceAuthorized = function (){
                

                console.log(vm.devices);
                console.log(vm.fingerprint);
                Auth.verifyDeviceAccess(vm.fingerprint)
                    .then(approved => {
                        console.log(approved);
                        if (approved) {
                            console.log('Authorization already was granted for this device')
                            $state.go('app.home')
                        }
                    })
                    .catch(err => {
                        console.log(err.data);
                        if(err && err.data && err.data.message && err.data.message ==='Unauthorized device') {
                            vm.requestSent = false
                        }
                        if(err && err.data && err.data.message && err.data.message ==='Device already submitted, but not approved yet') {
                            vm.requestSent = true
                        }

                    })
            }

            


            vm.addDevice = function () {
                console.trace('add device...');

                let device = {
                    fingerprint: vm.fingerprint,
                    details: window.platform.description
                }

                $ionicLoading.show()
                Auth.device(device)
                    .then(function (result) {
                        ngNotify.success(result.data)
                        $state.go('auth.pending')
                    })
                    .catch(err => {
                        console.error(err);
                        if (err && err.data && err.data.message) {
                            return ngNotify.warning(err.data.message)
                        }
                        ngNotify.error('We could not complete this request, try again later')
                    })
                    .finally($ionicLoading.hide)
            }

        })
})();
