(function () {
    'use strict';
    angular
        .module('app.settings.update',[])
        .config(SettingsConfig)
        .component('settings',{
            templateUrl:'views/settings/settings.html',
            controller:'UsrSettingsCtrl'
        })
        .component('userSettings',{
            templateUrl:'views/settings/settings.html',
            controller:'SettingsCtrl'
        })
        .controller('SettingsCtrl', (ngNotify,$ionicLoading) => {

            console.log('Settings component')

        })

    function SettingsConfig ($stateProvider) {

        $stateProvider
            .state('app.settings', {
                url: "/settings",
                cache:false,
                views: {
                      'menuContent': {
                            template: `<ion-view title="Settings"><settings></settings></ion-view>`
                      }
                }
            })
    }

})();
