(function () {
    'use strict';

    angular
        .module('app.checkout',[])
            .config(function ($stateProvider) {
                $stateProvider
                    .state('app.checkout', {
                        url: "/checkout",
                        cache:false,
                        views: {
                              'menuContent': {
                                    template:`<ion-view title="Checkout"><checkout></checkout></ion-view>`
                              }
                        }
                    })
            })
            .component('checkout',{
                templateUrl:'views/checkout/checkout.html',
                controller:'CheckOutCtrl'
            })
            .controller('CheckOutCtrl',CheckOutCtrl)

    function CheckOutCtrl (Quote, $localStorage) {
          var vm = this;

          vm.$onInit = function () {
              vm.items = $localStorage.cart;
          }
    }
})();
