(function () {
  'use strict';

  // class="subtitle-mode"
  // style="padding:10px;background-color:green;border-radius:5px;color:white"

  angular
    .module('app.user.face',[])
      .component('userFace',{
        template: `
            <h2 class="greeting" title="{{$ctrl.user.email}}">
                Hi {{$ctrl.user.firstName}}
                <br ng-if="$ctrl.user.company.length>10">
                <span class="subtitle-company"
                 ng-if="$ctrl.user.company">{{$ctrl.user.company}}</span>
            </h2>
            <p class="message">Territory MGR:
             {{::$ctrl.user.territoryManager.firstName}} {{::$ctrl.user.territoryManager.lastName}}</p>
            <h4 class="button {{$ctrl.displayMarkup ?'button-positive animated tada':'button-assertive'}} button-full">
                {{$ctrl.displayMarkup ?'Sales Mode ON':'Quoting Mode ON'}}
            </h4>`,

        controller:'UserFaceCtrl'
      })
      .controller('UserFaceCtrl', function ($localStorage, User, $rootScope, Auth, $state) {

        var vm = this;

        vm.$onInit = function () {
            vm.user = {};
            vm.user = $localStorage.user

            // display mode settings
            vm.displayMarkup = $localStorage.displayMarkup
            // console.log(`Display Markup: ${vm.displayMarkup}`)

            if ($localStorage.token) {
                getProfile();
            }
        }

        $rootScope.$on('updateProfile',function () {
          getProfile();
        })




        function getProfile() {
            User.profile()
                .then(function (result) {
                    $localStorage.user = result.data
                    vm.user = result.data                    
                })
                .catch(err => console.error(err.data))
        }
        

      })
})();
