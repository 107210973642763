(function () {
    'use strict';

    angular
        .module('app.admin.system-manager',[])
            .config(config)
            .component('systemManager',{
                templateUrl:'views/admin-systems/system-manager.html',
                controller:ComponentCtrl
            })

    function ComponentCtrl (Admin, Quote, ngNotify, $ionicLoading, $stateParams, System, $ionicPopup) {

        var vm = this

        let unitTypeId = $stateParams.unitTypeId||4
        vm.active = unitTypeId;


        vm.$onInit = function () {            

            vm.currentPage = 0;
            vm.pageSize = 100;

            vm.active = $stateParams.unitTypeId

            System.getByTypeWithoutSpecs(unitTypeId)
                .then(result => {
                    vm.systems = result.data

                    vm.numberOfPages = Math.ceil(vm.systems.length/vm.pageSize);

                    // vm.systems.forEach( sys => sys.models = System.getModels(System.parseProperties(sys)) )
                })
                .catch(ngNotify.danger)

        }
        
        vm.countModified = () => {
            let selectedSystems = vm.systems.filter( p => p.selected);
            vm.selectedSystems = selectedSystems.length
            console.log(vm.selectedSystems);
        }
        
        vm.remove = function (ahri) {            

            let confirmDelete = $ionicPopup.confirm({
                template: 'Are you sure you want to delete this system?'
            })

            confirmDelete.then(sure => {
                if (sure) {
                    let element = document.getElementById('item-' + ahri)
                    element.style.display = 'none'
                    System.remove(ahri)
                        .then(ngNotify.success)                        
                        .catch(err => {
                            ngNotify.danger(err)
                            element.style.display = 'inline'
                        })
                }
            })
        }

        vm.removeBatch = function (arr, index) {

             let selectedSystems = vm.systems.filter( p => p.selected);
            console.log(selectedSystems);
            
            let systemsToDelete = ''

            selectedSystems.forEach(p => systemsToDelete += p.ahri + ', ' )
            
            console.log('Deleting multiple products');
            var confirmPopup = $ionicPopup.confirm({
                title: 'Delete Item',
                template: 'Are you sure you want to delete these systems? <br>' +  systemsToDelete
            });

            confirmPopup.then(function(res) {
                if(res) {
                    
                    let promises = selectedSystems.map(p => System.remove(p.ahri)                        
                        .then(() => {                            
                            let elem = document.getElementById('item-' + p.ahri);
                            return elem.parentNode.removeChild(elem);
                        })
                    )

                    if (promises.length === 0) return ;

                    $ionicLoading.show()
                    Promise.all(promises)
                        .then(() => {
                            $ionicLoading.hide()
                            ngNotify.success('Systems deleted successfully')
                        })
                        .catch(ngNotify.danger)                        
                                            
                }
            });
        }


    }


    function config ($stateProvider) {
        $stateProvider
            .state('admin.system-manager', {
                url: "/systems?unitTypeId",
                cache:false,
                views: {
                      'menuContent': {
                            template:`<ion-view title="Manage System">
                            <system-manager></system-manager></ion-view>`
                      }
                  }
              })
    }
})();
