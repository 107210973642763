(function () {
    'use strict';
    angular
        .module('app.admin.categories',[])
        .config(function ($stateProvider) {

            
            $stateProvider
                .state('admin.categories', {
                    url: '/categories',                                        
                    cache:false,
                    views: {
                        'menuContent': {
                                template:`
                                <ion-view title="Categories"><admin-categories></admin-categories></ion-view>`
                        }
                    }
                })
        })
        .component('adminCategories',{
            templateUrl:'views/admin-categories/categories.html',            
            controller: Ctrl
        })

    function Ctrl (Admin, ngNotify, Quote, $ionicPopup, $ionicLoading, $state) {

        var vm = this
        console.log('admin categories');

        vm.$onInit = function () {
            Quote.catalogs()
                .then(data => vm.catalogs = data.data)                
                .catch(err => {
                    console.error(err.data)
                    ngNotify.danger(err)
                })
        }


        vm.addCategory = function (type) {
            let create = Admin[type]

            let _type = type === 'addUnitType' ? 'unit-type' : 'product-type'
            
            let popup = $ionicPopup.prompt({ title: 'Category name' })
            
            popup.then(result => {

                console.log(result);
                if (!result) return ;                
                
                $ionicLoading.show()
                create({ name: result })
                    .then(result => $state.go('admin.category',{ id: result.data, type: _type }))
                    .catch(ngNotify.danger)
                    .finally($ionicLoading.hide)
            })
                
                
        }

    }

})();
