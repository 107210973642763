(function () {
    'use strict';

    angular
        .module('app.files.submittal', [])
            .component('submittal',{
                template: `
                    <button class="button button-small" ng-if="$ctrl.loading">
                        <ion-spinner></ion-spinner>
                        Loading specs
                    </button>
                    <a class="button button-positive button-outline button-small margin10"
                        target="_blank"
                        ng-if="$ctrl.submittal.key"
                        href="{{ ::$ctrl.CDN  + $ctrl.submittal.key }}">
                        Submittal for {{ $ctrl.submittal.key|removePDFExtension }}
                    </a>
                `,
                controller: ComponentCtrl,
                bindings: { model: '<' }
            })

    function ComponentCtrl (File, CDN) {

        var vm = this
        vm.CDN = CDN

        vm.$onInit = function () {
            search()
        }

        // update manual if the model number changes
        vm.$onChanges = () => search()


        function search () {
            vm.loading = true
            vm.submittal = null
            File.submittals(vm.model)
                .then(data => vm.submittal = data.data[0]||null )                
                .catch(err => console.error(err.data))
                .finally( () => vm.loading = false )
        }

    }

})();