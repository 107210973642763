(function () {
    'use strict';
    angular
        .module('app.admin.import-system', [ 'app.system.service' ])
            .config(Config)
            .component('importSystem', {
                templateUrl: 'views/admin-import/admin-import.html',
                controller: ComponentCtrl
            })

    function ComponentCtrl ($ionicLoading, $localStorage, System, ngNotify, _, ionicToast, $q, $state) {

        var vm = this

        vm.$onInit = function () {
            // get what properties belong to each type of system
            vm.catalogs = $localStorage.catalogs
            
        }

        vm.getSystemProperties = function (unitType) {
            unitType = JSON.parse(unitType)
            vm.selectedUnitType = vm.catalogs.unitTypes.find(u => u.id === unitType.id)
            console.log(vm.selectedUnitType)

        }

        vm.viewProperties = function () {
            let data = document.getElementById('text-from-excel').value

            let rows = data.split('\n')

            try {
                // detect headers row, make it an array to be used on the system JSON data
                let columNames = rows
                    .find( d => d.indexOf('Unit Type')>-1 || d.indexOf('AHRI')>-1)
                    .split('\t')
                    .map( c => c = _.pascalCase(c) )

                console.log(columNames);

                vm.validationErrors = []
                
                // verify that the data from the users has all the required fields: common fields + unit type specific ones
                let defaultFields = System.defaultFields.map(_.pascalCase)

                defaultFields.forEach(prop => columNames.indexOf(prop)===-1 ? vm.validationErrors.push(prop) : 0 )

                vm.selectedUnitType.fields.forEach(prop => columNames.indexOf(prop)===-1 ? vm.validationErrors.push(prop) : 0 )


            } catch (e) {
                console.warn('posted data can\'t be parsed')   
                console.log(e)             
                ngNotify.error('The pasted data can\'t be imported')
            }

        }

        vm.reset = function () {
            $state.reload();
        }     

        vm.import = function () {

            try {
                $ionicLoading.show()

                // use native instead of angular for improvement performance
                let data = document.getElementById('text-from-excel').value

                let rows = data.split('\n')

                let systemsToImport = []

                // detext headers row, make it an array and
                let columNames = rows
                    .find( d => d.indexOf('Unit Type')>-1)
                    .split('\t')
                    .map( c => c = _.pascalCase(c) )

                // create object with the column names as properties
                rows.forEach(row => {
                    let cols = row.split('\t')
                    let system = {}
                    cols.forEach((val,key) => system[columNames[key]] = _.trimIfString(val) )
                    systemsToImport.push(system)
                })

                vm.exampleRow = systemsToImport[1]

                // remove empty objects,
                // using ">" helps to avoid empty rows and table headersw headers
                systemsToImport = systemsToImport.filter( r => r.Ahri && r.Ahri !== 'AHRI' )

                let systemFields = vm.selectedUnitType.fields

                console.log(systemFields);



                // let catalogs = $localStorage;
                systemsToImport.forEach(system => {

                    let jsonObject = {}
                    _.each(system, (val, key) => {
                        if (key.indexOf('onnage') > -1) {
                            if (typeof val === 'string') {
                                val = val.replace(' Ton','')
                                val = parseFloat(val)
                            }
                        }
                        if (systemFields.indexOf(key)> -1) { jsonObject[key] = val }
                    })

                    system.UnitTypeId = vm.selectedUnitType.id

                    system.data = JSON.stringify(jsonObject)
                })

                console.log(systemsToImport);

                console.log(`Importing ${systemsToImport.length} systems...` );

                // if the array is too big it causes errors on the backend
                // we will split it into chunks to avoid that error
                // IMPORTANT NOTE: import might not be working for importing more than 1000 products
                // if (systemsToImport.length > 1000) {

                //     let chunks = _.chunk(systemsToImport, 200)

                //     let promises = []

                //     promises = chunks.map(System.import)

                //     $q.all(promises)
                //         .then(result => ngNotify.set('Process completed',{ sticky: true }) )
                //         .catch(err => {
                //             console.error(err);
                //             ngNotify.danger(err.data)
                //         })
                //         .finally($ionicLoading.hide)

                // } else {
                    System.import(systemsToImport)
                        .then(result => ngNotify.set(result.data,{ sticky: true }) )
                        .catch(ngNotify.danger)
                        .finally($ionicLoading.hide)
                // }
            } catch (err) {
                $ionicLoading.hide();
                ngNotify.error('There was an error parsing the data. This error might be caused because the table headers are not present')
            }

            

        }

    }

    function Config ($stateProvider) {
        $stateProvider
            .state('admin.import-systems', {
                url: '/import-systems',
                cache: false,
                views: {
                      'menuContent': {
                            template:`
                            <ion-view title="Admin - Import System from Excel">
                                <import-system></import-system>
                            </ion-view>`
                      }
                }
            })
    }
})();


// system.tonnage = system.CondenserTonnage

// let AirHandlerBrandId = ImportParser
//     .findByName(catalogs.brands, system.AirHandlerBrand)
//
// let MiniSplitBrandId = ImportParser
//     .findByName(catalogs.brands, system.MiniSplitBrand)
//
// system.UnitTypeId = 6

// system.data = {
//     HSPF: system.Hspf,
//     AirHandlerBrandId: AirHandlerBrandId,
//     AirHandlerModelNo: system.AirHandlerModelNo,
//     AirHandlerTonnage: system.AirHandlerTonnage.replace(' Ton', ''),
//     CondenserTonnage: system.CondenserTonnage.replace(' Ton', ''),
//     MiniSplitBrandId: MiniSplitBrandId,
// }





// vm.systemType = systemsToImport[0].UnitType
// console.log(`Data to be imported corresponds to: ${vm.systemType}`);
// console.log(systemsToImport[0]);
// array using the properties and format ready to be inserted to the DB
// let parsedArray = []
