(function () {
    'use strict';
    angular
        .module('app.cart.component',[])
            .config(ShoppingConfig)
            .component('shoppingCart',{
                templateUrl:'views/shopping-cart/shopping-cart.html',
                controller: ShoppingCartCtrl
            })

    function ShoppingCartCtrl (Quote, ngNotify, $ionicLoading, $ionicPopup, $localStorage, Cart, $state, System, $rootScope) {

        var vm = this

        vm.$onInit = function () {

            vm.job = ''
            vm.displayMarkup = $localStorage.displayMarkup

            vm.items = $localStorage.cart
            console.log(vm.items)
        }


        vm.removeFromCart = function (index) {
            var confirmPopup = $ionicPopup.confirm({
                title: 'Remove from cart',
                template: 'Are you sure you want to remove this equipment from the quoting cart?'
            })

            confirmPopup.then(res => {
                if (res) {
                    Cart.remove(index)
                    console.log('Reloading cart')
                    $state.go($state.current, {}, { reload: true });
                }
            })

        }

        // go back to modify an item from the cart
        vm.edit = function (item) {

            console.log('Edit item');
            console.log(item);

            // remove properties that do not belong to the product itself
            let editItem = _.clone(item)
            delete editItem.tag
            delete editItem.tagColor
            delete editItem.quantity
            delete editItem.productModels

            let params = { selected: editItem }

            if (item.type === 'Cooling System') {
                return $state.go('app.cooling-system', params)
            }


            if (item.unitTypeId) {
                switch (item.unitTypeId) {
                    case 1:
                        $state.go('app.packaged-unit', params)
                        break;
                    case 2:
                        $state.go('app.packaged-unit', params)
                        break;
                    case 3:
                        $state.go('app.heat-pump', params)
                        break;
                    case 4:
                        $state.go('app.cooling-system', params)
                        break;
                    case 5:
                        $state.go('app.condenser-airhandler', params)
                        break;
                    case 6:
                        $state.go('app.mini-split', params)
                        break;
                    default:

                }
            }

            if (item.productTypeId) {
                params.id = item.productTypeId
                $state.go('app.product-selection', params)
            }
        }

        // tags are currenlty used by contractors, we will use them to group the tasks using a term which contractors are already familiar with
        vm.addTag = function (item) {

            $ionicPopup.prompt({ title: 'Tag name to identify the group of this item' })
                .then(tag => {
                    if (tag) {
                        item.tagColor = stringToColour(tag)
                        item.tag = tag
                    }
                })
        }

        // delete the tag from an item in the shopping cart
        vm.deleteTag = item => {
            var confirmPopup = $ionicPopup.confirm({
                title: 'Remove Tag',
                template: 'Are you sure you want to remove the tag of this item?'
            })

            // confirm('Do you want to reset this tag?') ? delete item.tag : 0;
            confirmPopup.then(res => res ? delete item.tag : 0 )
        }

        vm.suggestAccessories = Cart.suggestAccessories


        vm.generateQuote = function () {
            console.log('generating quote');
            $ionicLoading.show()

            let quoteItems = []

            // a system contains multiple products from different categories
            // this is a provitional measure to describe what models are included
            vm.items.forEach(item => {                

                let quoteItem = {                    
                    quantity: item.quantity,
                    tag: item.tag,
                    price: 0,
                    finalPrice: 0,                    
                }

                if (item.modelNo) {
                    quoteItem.modelNo = item.modelNo
                }

                if (item.ahri) {
                    quoteItem.ahri = item.ahri
                    // quoteItem.productModels = System.getModels(item)
                }

                quoteItems.push(quoteItem)
            })

            console.log(vm.items);
                        
            vm.quote.quoteItems = quoteItems

            Quote.create(vm.quote)
                .then(result => {
                    ngNotify.success(result.data.message)
                    $localStorage.cart = null
                    $localStorage.cart = []
                    $rootScope.$broadcast('updateCart')
                    Cart.sync()
                    $state.go('app.quote', { quoteId: result.data.quoteId })
                })
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

    }

    function ShoppingConfig($stateProvider) {
        $stateProvider
            .state('app.cart', {
                url: "/cart",
                cache:false,
                views: {
                      'menuContent': {
                            template:`
                            <ion-view title="Quoting Cart"><shopping-cart></shopping-cart></ion-view>`
                      }
                }
            })
    }

    function stringToColour(str) {
        if (!str) { return ; }
        // jshint ignore: start
        var hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (let i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
        // jshint ignore: end
    }


})();



// item.description = `SEER: ${item.seer}, EER: ${item.eer} <br>`

// let itemFields = Object.keys(item)


// let possibleTonnages = itemFields.filter(i => i.indexOf('onnage') > -1)
// if (possibleTonnages && possibleTonnages.length > 0) {
//     possibleTonnages.map(possibleTon => item.description += `${_.titleCase(possibleTon)}: ${item[possibleTon]}.<br>`)
// }

// let possibleEfficiency = itemFields.filter(i => i.indexOf('fficiency') > -1)
// if (possibleEfficiency && possibleEfficiency.length > 0) {
//     possibleEfficiency.map(possible => item.description += `${_.titleCase(possible)}: ${item[possible]}.<br>`)
// }