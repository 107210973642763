(function () {
  'use strict';

  angular.module('underscore', [])
  .factory('_', function() {
    return window._; // assumes underscore has already been loaded on the page
  });

  // angular.module is a global place for creating, registering and retrieving Angular modules
  // 'app' is the name of this angular module example (also set in a <body> attribute in index.html)
  angular.module('app', [
    'ionic',
    'app.helper',
    'app.pdf',
    'pdf',
    'app.routes', // angular shared routes
    'app.main', // main controller where we can check the progress of the user
    'app.auth', // email login/signup and authorization
    'app.quotes',
    'app.home', // dashboard, welcome screen
    'app.admin', // configure the mobile aplication
    'app.checkout', // display pricing and trigger action to send quote by email
    'app.components',
    'app.cart', // quoting cart, generate quote
    'app.products',
    'app.branch', // howard induestries branches, they apply different branches
    'app.files',
    'app.product-selection',
    'app.print', // print div
    'app.directives',
    'app.config', // endpoint configuration and global variables
    'app.filters',
    'app.settings', // change logo, markup, contact info
    'app.managers', // territory managers dashboard
    'app.views', // compiled views containing all the templates concatenated
    'app.user',
    'ionic-toast', // toast alerts android alike
    'ngNotify', // success and error alerts
    'underscore',
    'ngStorage',
    'ngCordova',
    'ngFileUpload',  
    'angular-loading-bar',
    'ngIdle'
    // 'app.settings', // [ pending ]
  ])
  .config(function () {
      window._.mixin({ 'pascalCase': _.flow(_.camelCase, _.upperFirst) });
      window._.mixin({ 'titleCase': _.flow(_.camelCase, _.startCase) });

      // remove whitespace at the begining and end of the value, but apply only if the value is a string, in order to avoid transforming decimals to strings
      window._.mixin({ 'trimIfString': data => _.isString(data) ? _.trim(data): data })      
  })
  .config(function(IdleProvider, KeepaliveProvider) {
    KeepaliveProvider.interval(2000);
    IdleProvider.windowInterrupt('focus');
  })
  .run(function($ionicPlatform, $rootScope, $ionicConfig, $localStorage, ngNotify, _, User, Idle) {

    // iphone "done" button on select boxes
    if (window.cordova && window.cordova.plugins.Keyboard) {
      window.cordova.plugins.Keyboard.hideKeyboardAccessoryBar(false);
    }


    Idle.watch();

    

    ngNotify.config({
      theme: 'pure',
      html: true,
      duration: 10000,
    });

    window.services = {}
    window.services.User = User
    window.$localStorage = $localStorage

    // we add methods to the ngNotify object to simplify calling the function
    ngNotify.error = text =>
        ngNotify.set(typeof text ==='object' ? text.data : text,'error');

    ngNotify.danger = function (err) {
        if (typeof err === 'object') {

            let status = err.status

            // don't display error if it's authorization error, since we would redirect the user instead
            if (status && status !== 401) {
                let message = _.get(err,'err.data.message')
                ngNotify.set(message || 'Something went wrong, please try again later.', 'error')
            }

            console.error(err.data)

        } else {
            console.error(err)
            ngNotify.set(err,'error')
        }
    }

    ngNotify.success = text =>
        ngNotify.set(typeof text ==='object' ? text.data : text,'success');

    ngNotify.warning = text =>
        ngNotify.set(typeof text ==='object' ? text.data : text,'warn');

    // we init the local database "tables"
    $localStorage.$default({
      user: {},
      cart: [],
      hideInstructions: false,
      displayMarkup: false, // user mode: sales vs quoting
      token: null // backend token
    });

    // This fixes transitions for transparent background views

    // $rootScope.$on("$stateChangeStart", function(event, toState, toParams, fromState, fromParams){
    //
    //   if(toState.name.indexOf('auth.walkthrough') > -1 || toState.name.indexOf('auth.login') > -1)
    //   {
    //     // set transitions to android to avoid weird visual effect in the walkthrough transitions
    //     $timeout(function(){
    //       $ionicConfig.views.transition('android');
    //       $ionicConfig.views.swipeBackEnabled(false);
    //     	console.log("setting transition to android and disabling swipe back");
    //     }, 0);
    //   }
    // });


    $ionicPlatform.on("deviceready", function(){
      // Hide the accessory bar by default (remove this to show the accessory bar above the keyboard
      // for form inputs)
      if(window.cordova && window.cordova.plugins.Keyboard) {
        cordova.plugins.Keyboard.hideKeyboardAccessoryBar(true);
      }
      if(window.StatusBar) {
        window.StatusBar.styleDefault(1);
      }

      // PushNotificationsService.register();
    });

  });

})();



// function allServices(mod, r) {
//     if (!r) {
//     r = {};
//     inj = angular.element(document.querySelector('[ng-app]')).injector().get;
//     }
//     angular.forEach(angular.module(mod).requires, function(m) {allServices(m,r)});
//     angular.forEach(angular.module(mod)._invokeQueue, function(a) {
//       try { r[a[2][0]] = inj(a[2][0]); } catch (e) {}
//     });
//     return r;
// };

// var Services = allServices('app');

// $ionicPlatform.on("resume", function(){
//   PushNotificationsService.register();
// });
