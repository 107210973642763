(function () {
  'use strict';

  angular
    .module('app.admin',[
      'app.admin.service',      
      'app.admin.home', // admin dashboard
      'app.admin.user', // admin user details
      // 'app.admin.user-quotes',
      // detail of the quotes generated by the user
      'app.admin.users', // authorize access
      'app.admin.button',

      /*  product and unit type modules  */
      'app.admin.categories',
      'app.admin.category',

      /*    Manage systems and products    */
      'app.admin.system-manager',
      'app.admin.system-tabs',
      'app.admin.edit-system',
      'app.admin.edit-product',
      'app.admin.import-products',

      'app.admin.report',

      /*    admin sub-module, territory managers    */
      'app.admin-managers',

      /*    pricing module  */
      'app.admin.pricing',
      'app.admin.merge-products',
      // 'app.admin.import',
      'app.admin.parser',
      'app.admin.import-system',

    ])
    .config(function($stateProvider, $urlRouterProvider) {

        $stateProvider

        .state('admin', {
          url: "/admin",
          abstract: true,
          templateUrl: "views/admin/admin-menu.html",
          controller: 'AppCtrl',
          controllerAs:'$ctrl'
        })
       
      });
})();
