(function () {

    angular

        .module('app.admin.system-tabs',[])

            .component('adminSystemTabs', {
                template: `
                <div style="height:40px">.</div>
                <div class="button-bar">
                    <a ng-repeat="unit in $ctrl.unitTypes"
                        style="font-size:11px"
                        class="button {{$ctrl.active==unit.id ?'gray':''}}"
                        ng-click="$ctrl.go({ unitTypeId: unit.id })" nav-transition="none">
                        {{::unit.name}}
                    </a>
                </div>`,
                bindings: {
                    active: '@'
                },
                controller: ComponentCtrl
            })

    function ComponentCtrl ($state, $ionicHistory, $stateParams, $localStorage) {

        var vm = this

        vm.unitTypes = $localStorage.catalogs.unitTypes


        vm.$onInit = function () {


            vm.active = $stateParams.unitTypeId;

        }

        vm.go = function (params) {
            $ionicHistory.nextViewOptions({
                disableAnimate: true
            });

            $state.go('admin.system-manager', params)
        }


    }
})();
