(function () {

    'use strict';

    angular
        .module('app.admin.user',[])
            .config(Config)
            .component('adminUser',{
                templateUrl: 'views/admin-users/admin-user.html',
                controller: ComponentCtrl
            })

    function ComponentCtrl (Admin, ngNotify, $ionicLoading, $stateParams, $state, User, $ionicPopup, Product, CDN) {

        var vm = this

        vm.$onInit = function () {

            vm.cdn = CDN
            // set active tab
            vm.active = $stateParams.active ? $stateParams.active : 'information'
            console.log('admin user details component');
            getUser()

            // get territory managers
            User.managers()
                .then(result => vm.managers = result.data)
                .catch(ngNotify.danger)

            // get product categories
            Product.groups()
                .then(result => vm.groups = result.data)
                .catch(ngNotify.danger)
        }

        function getUser () {
            $ionicLoading.show()
            Admin.user($stateParams.id)
                .then(data => {
                    vm.user = data.data

                    console.log(vm.user);
                    if (vm.user.generatedQuotes) {                        
                        vm.user.generatedQuotes = vm.user.generatedQuotes.reverse()
                    }
                })
                .catch(err => {
                    console.error(err.data);
                    ngNotify.error('An error occurred while loading the user\'s information, please try again later')
                })
                .finally($ionicLoading.hide)
        }



        vm.save = function () {
            $ionicLoading.show()
            Admin.updateUser(vm.user)
                .then(ngNotify.success)
                .catch(ngNotify.error)
                .finally($ionicLoading.hide)
        }

        vm.toggleDevice = function (device) {
            $ionicLoading.show()
            Admin.toggleDevice(device.id)
                .then(data => {
                    ngNotify.success(data.data)
                    device.approved = !device.approved
                })
                .catch(err=>{
                    console.error(err);
                    ngNotify.error('An error occurred while loading the users, please try again later')
                })
                .finally($ionicLoading.hide)
        }

        vm.toggleAdminRole = function () {
            $ionicLoading.show()
            Admin.toggleAdmin($stateParams.id)
                .then(data => {
                    ngNotify.success(data.data)
                    $state.reload();
                })
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

        vm.toggleManagerRole = function () {
            $ionicLoading.show()
            Admin.toggleManager($stateParams.id)
                .then(data => {
                    ngNotify.success(data.data)
                    $state.reload();
                })
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

        vm.addMultiplier = function () {
            $ionicLoading.show()
            vm.multiplier.userId = $stateParams.id
            Admin.addMultiplier(vm.multiplier)
                .then(data => {
                    ngNotify.success(data.data)
                    // reset form
                    vm.multiplier = {}
                    getUser()
                })
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

        vm.removeMultiplier = function (id) {
            console.log(id);
            var confirmPopup = $ionicPopup.confirm({
                title: 'Remove Multiplier',
                template: 'Are you sure you want to remove this item?'
            })

            confirmPopup.then(res => {
                if (res) {
                    $ionicLoading.show()
                    Admin.removeMultiplier(id)
                        .then(data => {
                            ngNotify.success(data.data)
                            getUser()
                        })
                        .catch(ngNotify.danger)
                        .finally($ionicLoading.hide)
                }
            })
        }

        vm.deleteDevice = function (device) {
            var confirmPopup = $ionicPopup.confirm({
                title: 'Remove Device',
                template: 'Are you sure you want to remove this item? The user will not be able to use the system using this device anymore'
            })

            confirmPopup.then(res => {
                if (res) {
                    $ionicLoading.show()
                    Admin.deleteDevice(device.id)
                        .then(data => {
                            ngNotify.success(data.data)
                            getUser()
                        })
                        .catch(ngNotify.danger)
                        .finally($ionicLoading.hide)
                }
            })
        }

    }

    function Config ($stateProvider) {
        $stateProvider
            .state('admin.user', {
                url: "/user/:id",
                cache:false,
                params: {
                    active: null
                },
                views: {
                      'menuContent': { template:`<ion-view title="Admin - User Details"><admin-user></admin-user></ion-view>` }
                }
            })
    }
})();
