(function () {
  'use strict';
  /*
  Rutas de la aplicación
  */
  angular
    .module('app.routes', [])
      .config(function($stateProvider, $urlRouterProvider) {

        $stateProvider


        .state('app', {
          url: "/app",
          abstract: true,
          templateUrl: "views/shared/views/side-menu.html",
          controller: 'AppCtrl',
          controllerAs:'$ctrl'
        })

        .state('app.home', {
          url: "/home",
          views: {
            'menuContent': {
              template:`<ion-view title="Home"><home></home></ion-view>`
            }
          }
        })                

        // if none of the above states are matched, use this as the fallback
        $urlRouterProvider.otherwise('/app/home');
      });

})();
