(function () {
    'use strict';
    angular
        .module('app.system.service', [])
            .factory('System', function (API, $http, _) {
                return {
                    import: function (data) {
                        let endpoint = `${API}/api/admin/import`
                        return $http.post(endpoint, data)
                    },
                    getByType: function (unitTypeId) {
                        let endpoint = `${API}/api/systems?UnitTypeId=${unitTypeId}&specs=true`
                        return $http.get(endpoint)
                    },
                    getByTypeWithoutSpecs: function (unitTypeId) {
                        let endpoint = `${API}/api/systems?UnitTypeId=${unitTypeId}&specs=false`
                        return $http.get(endpoint)
                    },
                    show: function (ahri) {
                        let endpoint = `${API}/api/systems/${ahri}`
                        return $http.get(endpoint)
                    },
                    update: function (data) {
                        let endpoint = `${API}/api/systems/${data.ahri}`
                        return $http.put(endpoint, data)
                    },                    
                    remove: function (id) {
                        let endpoint = `${API}/api/systems/${id}`
                        return $http.delete(endpoint)
                    },
                    getModels: function (system) {
                        let models = []
                        _.each(system, (val, key) => {
                            if (key.indexOf('odelN')>-1 && val) {
                                // console.log(`${key}: ${val}`)
                                models.push(val)
                            }
                        })

                        return models
                    },
                    getModelsAsync: function (system) {
                        let models = []
                        let endpoint = `${API}/api/systems/${system}`
                        return $http.get(endpoint)
                            .then(function (result) {
                                _.each(result.data, (value, key) => {
                                    if (key.indexOf('odeN') > -1) {
                                        models.push(value)
                                    }
                                })

                                return models;
                            })
                    },
                    parseProperties: function (system) {
                        try {
                            // if there is no AHRI number then it's a single product
                            if (system && system.ahri) {                                
                                system.data = JSON.parse(system.data)
                                // add json properties to the main object to facilitate system filters
                                _.each(system.data, (value, key) => system[_.camelCase(key)] = value )
                                delete system.data;                            
                            }

                            
                        } catch (e) {
                            console.log(system);
                            console.error(e);
                        }
                    
                        return system

                    },
                    productsFromUnitType: function (id) {
                        let endpoint = `${API}/api/systems?UnitTypeId=${id}&specs=true`
                        return $http.get(endpoint)
                    },
                    // define which filters will be active when the user clicks on edit system from the quoting cart screen
                    filterFields:{
                        // minisplit
                        6: [
                            'miniSplitBrand',
                            'condenserTonnage',
                            'airHandlerBrand',
                            'airHandlerTonnage',
                            'hspf',
                            'zones'
                        ]
                    },
                    // used to know the common fields between all the systems
                    // Mainly for the importing process
                    defaultFields: ['eer', 'seer', 'voltage','ahri'],

                    // filtering login will group system based on these
                    defaultFieldsForFilters: ['eer', 'seer', 'voltage']

                }
            })
})();
