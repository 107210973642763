(function () {

    'use strict';

    angular
        .module('app.user.profile',[])
            .config(ProfileConfig)
            .component('userProfile',{
                templateUrl: 'views/users/profile.html',
                controller: UserProfileCtrl
            })

    function ProfileConfig ($stateProvider) {

        $stateProvider
            .state('app.profile', {
                url: "/profile",
                cache:false,
                views: {
                      'menuContent': {
                            template: `<ion-view title="Profile"><user-profile></user-profile></ion-view>`
                      }
                }
            })
    }

    function UserProfileCtrl (CDN, API, User, ngNotify, $stateParams, $ionicLoading, $rootScope, $localStorage, $window, Upload, $state) {

        var vm = this;

        vm.$onInit = function () {
            // activate main tab, the other one is "password"
            vm.active = 'information'
            vm.cdn = CDN
            vm.displayMarkup = $localStorage.displayMarkup

            $ionicLoading.show();
            // get territory managers
            User.managers()
                .then(result => vm.managers = result.data)
                .catch(function (err) {
                    console.error(err);
                })
            
            // get user's information
            User.profile()
                .then(data => vm.user = data.data)
                .catch(function (err) {
                    console.error(err);
                    ngNotify.warning('We could not load this user\'s profile ')
                })
                .finally($ionicLoading.hide);
        }
        


        // update user profile, it will only update basic data
        vm.update = function () {
            $ionicLoading.show()
            User.update(vm.user)
                .then(ngNotify.success)
                .then($rootScope.$broadcast('updateProfile'))
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)

            $localStorage.user.markup = vm.user.markup
        }

        vm.toggleMode = function () {            

            $ionicLoading.show()
            $localStorage.displayMarkup = !$localStorage.displayMarkup
            $ionicLoading.hide()
            window.location.reload()

        }

        // change profile picture / invoices custom logo
        vm.upload = function () {
            $ionicLoading.show()
            Upload.upload({                
                url: `${API}/api/account/picture?name=${vm.logo.name}`,
                method: 'POST',
                file: vm.logo
            }).then(function (resp) {                
                ngNotify.success(resp.data.message)
                $ionicLoading.hide() 
                $state.reload()               
            }, function (resp) {
                $ionicLoading.hide()
                ngNotify.error(resp.data.message||'Error. Try again later')                
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
            });
        }

        vm.uploadAttachment = function () {
            $ionicLoading.show()
            Upload.upload({                
                url: `${API}/api/account/attachment?name=${vm.attachment.name}`,
                method: 'POST',
                file: vm.attachment
            }).then(function (resp) {                
                ngNotify.success(resp.data)
                $ionicLoading.hide() 
                $state.reload()               
            }, function (resp) {
                $ionicLoading.hide()
                ngNotify.error(resp.data.message||'Error. Try again later')                
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
            });
        }

        // update/encrypt new password
        vm.updatePassword = function () {
            $ionicLoading.show()
            User.password(vm.user)
                .then(ngNotify.success)
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

    }

})();
