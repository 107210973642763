(function () {
    'use strict';

    // Module for TERRITORY MANAGERS
    angular
        .module('app.admin-managers',[
            // 'app.admin-managers.service', // backend requests
            'app.admin-managers.list', // display all territory managers
            'app.admin-managers.detail', // show single manager details
        ])
})();
