(function () {
    'use strict';
    /*
    Useful for editing product and unit types
    BUUUUT by now we will give priority to product types
    */
    angular
        .module('app.admin.category',[])
        .config(function ($stateProvider) {

            
            $stateProvider
                .state('admin.category', {
                    url: '/category/:type/:id',                                        
                    cache:false,
                    views: {
                        'menuContent': {
                                template:`
                                <ion-view title="Category details"><admin-category></admin-category></ion-view>`
                        }
                    }
                })
        })
        .component('adminCategory',{
            templateUrl:'views/admin-categories/category.html',
            controller: Ctrl
        })

    function Ctrl (Admin, ngNotify, Quote, $ionicPopup, $ionicLoading, $stateParams, _) {

        var vm = this        

        vm.$onInit = function () {
            console.log('Admin categories');
            Quote.catalogs()
                .then(data => {
                    vm.catalogs = data.data

                    let type = _.camelCase($stateParams.type)

                    console.log(vm.catalogs[type]);

                    vm.category = vm.catalogs[type].find(item => item.id == $stateParams.id)
                    console.log(vm.category);
                })
                .catch(err => {
                    console.error(err.data)
                    ngNotify.danger(err)
                })
        }


        vm.addCategory = function (type) {
            let create = Admin[type]
            
            $ionicPopup.prompt({ title: 'Category name' })   
                .then(result => {
                    if (!result) return ;
                    
                    $ionicLoading.show()
                    create()
                        .then(result => $state.go('admin.category',{ id: result.data }))
                        .catch(ngNotify.danger)
                        .finally($ionicLoading.hide)
                })
                
                
        }

    }

})();
