(function () {
  'use strict';

  angular
    .module('app.managers.service',    [])
        .factory('Manager',function (API, $http) {

            return {

                all:function () {
                    let endpoint = API + '/api/managers';
                    return $http.get(endpoint);
                },
                create:function (data) {
                    let endpoint = API + '/api/managers';
                    return $http.post(endpoint, data);
                },
                show:function (id) {
                    let endpoint = API + '/api/managers/' + id;
                    return $http.get(endpoint);
                },
                update: function (data) {
                    let endpoint = API + '/api/managers/' + data.id
                    return $http.put(endpoint, data)
                },
                dashboard:function () {
                    let endpoint = API + '/api/managers/dashboard';
                    return $http.get(endpoint);
                },

            }
        });
})();
