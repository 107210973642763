(function () {
    angular
        .module('app.admin.pricing-detail', [])
            .config(Config)
            .component('pricingDetail',{
                templateUrl: 'views/admin-pricing/pricing-detail.html',
                controller: ComponentCtrl
            })

    function ComponentCtrl (Admin, $ionicLoading, ngNotify, $stateParams) {

        var vm = this

        vm.$onInit = function () {

            $ionicLoading.show()
            Admin.getPrice($stateParams)
                .then(data => vm.price = data.data)
                .then(ngNotify.danger)
                .finally($ionicLoading.hide)
                
        }


    }

    function Config ($stateProvider) {
        $stateProvider
            .state('admin.pricing-detail', {
                url: "/pricing",
                cache:false,
                views: {
                      'menuContent': {
                            template:`<ion-view title="Admin - Pricing"><pricing-list></pricing-list></ion-view>`
                      }
                }
            })
    }
})();
