(function () {
  'use strict';
  angular
    .module('app.home',[])
        .component('home',{
            templateUrl:'views/shared/views/home.html',
            controller:'HomeCtrl'
        })
        .controller('HomeCtrl',function ($localStorage, $state, User, $rootScope) {
            var vm = this;

            console.log('home component');            

            vm.$onInit = function () {                
                $rootScope.$broadcast('updateCart')
                vm.user = $localStorage.user
                User.profile()
                    .then(user => vm.user = user.data )
                    .catch(err => {
                        console.error(err.data)                    
                        if (err.status === 401) {
                            return $state.go('auth.login')
                        }

                    })

                if (vm.user && vm.user.userDevices) {
                    vm.devices = $localStorage.user.userDevices
                }

                if ($localStorage.user && $localStorage.user.isActive === false) {
                    $state.go('auth.pending')
                }
            }


            

        });
})();
