(function () {
    'use strict';
    angular
        .module('app.pdf', [])
            .config(Config)
            .component('viewPdf', {
                templateUrl: 'views/pdfs/viewer.html',
                controller: Ctrl
            })
            .controller('PdfCtrl', Ctrl)

    function Ctrl ($scope, API) {
        var vm = this

        $scope.pdfName = 'Relativity: The Special and General Theory by Albert Einstein';
        // $scope.pdfUrl = 'https://s3-us-west-1.amazonaws.com/howardindustries/submitals/MS-4TXK1612A10N0A.pdf';
        // $scope.pdfUrl = 'QuoteExample.pdf';
        $scope.pdfUrl = API + '/api/files?name=brochures/Ductless.pdf'
        $scope.pdfPassword = 'test';
        $scope.scroll = 0;
        $scope.loading = 'loading document...';

        $scope.getNavStyle = function(scroll) {
            if(scroll > 100) return 'pdf-controls fixed';
            else return 'pdf-controls';
        }

        $scope.onError = function(error) {
            console.error(error);
            alert(error)
        }

        $scope.onLoad = function() {
            vm.loading = '';
        }

        $scope.onProgress = function (progressData) {
            console.log(progressData);
        };

        $scope.onPassword = function (updatePasswordFn, passwordResponse) {
            if (passwordResponse === PDFJS.PasswordResponses.NEED_PASSWORD) {
                updatePasswordFn(vm.pdfPassword);
            } else if (passwordResponse === PDFJS.PasswordResponses.INCORRECT_PASSWORD) {
                console.log('Incorrect password')
            }
        };
    }

    function Config ($stateProvider) {
        $stateProvider
            .state('app.pdf', {
                url: "/pdf",
                cache:false,
                views: {
                      'menuContent': {
                            template:`
                            <ion-view title="View Document">
                                <ion-content ng-controller="PdfCtrl as $ctrl">
                                    <h2>asd</h2>
                                    <ng-pdf template-url="views/pdfs/viewer.html" scale="page-fit" ></ng-pdf>
                                </ion-content>
                            </ion-view>`
                      }
                }
            })
    }
})();