(function () {
    'use strict';
    angular
        .module('app.admin.user-quotes', [])
            .component('adminUserQuotes', {
                template: `
                <div class="card" ng-repeat="quote in $ctrl.quotes">
                    <div class="item item-divider">
                        {{quote.date|date:'MMMM dd yyyy'}}
                    </div>
                    <div class="item item-text-wrap" ng-repeat="item in $ctrl.quotes">
                        <strong>({{item.quantity}})</strong>
                        {{item.description}}
                        <strong>{{item.price}}</strong>
                    </div>
                    <div class="item item-divider">
                        Total: {{quote.totalPrice|currency}}
                    </div>
                </div>`,
                controller: Component
            })

    function Component (Admin, $ionicLoading, ngNotify) {

        var vm = this

        vm.$onInit = function () {
            $ionicLoading.show()
            Admin.userQuotes()
                .then(data => vm.quotes = data.data )
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

    }
})();
