(function () {
    'use strict';
    angular
        .module('app.quotes.system-instructions', [])
            .component('systemInstructions', {
                template: `
                <div class="card animated bounceIn" ng-cloak id="system-instructions"  ng-if="!$ctrl.hideInstructions">
                    <h3 class="hi-title">Instructions:</h3>
                    <ul class="list">
                        <li class="item">
                            <strong>(a)</strong> Input values for each category and select the equipment you are searching for.
                        </li>
                        <li class="item">
                            <strong>(b)</strong> Add the selected equipment to your "shopping cart" to continue quoting.
                        </li>
                        <li class="item">
                            <strong>(c)</strong> Change category values as needed.
                        </li>
                    </ul>
                    <button
                        class="button button-positive pull-right"
                        style="margin:10px"
                        ng-click="$ctrl.gotIt()">
                        <i class="icon ion-check"></i>
                        Got it
                    </button>
                </div>`,
                controller: Component
            })

    function Component ($localStorage) {
        var vm = this

        vm.$onInit = function () {
            vm.hideInstructions = $localStorage.hideInstructions
        }

        vm.gotIt = () => {
            $localStorage.hideInstructions = true
            let instructions = document.getElementById('system-instructions')
            console.log(instructions);
            instructions.className += ' animated'
            instructions.className += ' zoomOutDown'
            setTimeout(() => instructions.parentNode.removeChild(instructions) , 1000)
        }
    }
})();
