(function () {
    'use strict';

    angular
        .module('app.quotes.detail', [])
            .config(Config)
            .component('quoteDetail',{
                templateUrl:'views/quote-detail/quote-detail.html',
                controller: ComponentCtrl
            })

    function ComponentCtrl (ngNotify, $ionicLoading, Quote, $stateParams, $localStorage, System, $ionicPopup, $state, User, CDN, $window, File) {

        var vm = this

        vm.displayMarkup = $localStorage.displayMarkup

        vm.$onInit = function () {
            $ionicLoading.show()
            Quote.show($stateParams.quoteId)
                .then(result => vm.quote = result.data )                
                .then( ()=> console.log(vm.quote) )
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)

            vm.markup = vm.displayMarkup ? $localStorage.user.markup : 1
            vm.user = $localStorage.user

            vm.isAdmin = User.isAdmin();
        }

        

        vm.delete = id => {
            $ionicPopup.confirm({
                title: 'Do you want to delete this quote from the user profile?'
            }).then(sure => {
                if (sure) {
                    $ionicLoading.show()
                    Quote.delete(id)
                        .then(result => {
                            ngNotify.success(result.data)
                            $state.go('app.quotes-history')
                        })
                        .catch(ngNotify.danger)
                        .finally($ionicLoading.hide)
                }
            })
            
        }

        vm.hideColumns = function () {
            let hideElements = document.getElementsByClassName('hidden-print')
            console.log(hideElements)
            _.each(hideElements, elem => elem.style.display = 'none' )
        }

        vm.getPdf = function () {
            $ionicLoading.show()
            console.log('quoting mode: ' +  vm.displayMarkup )
            Quote.pdf(vm.quote.id, { displayMarkup: vm.displayMarkup, action: 'download' })
                .then(result => {
                    // ngNotify.success(result.data)
                    console.log(result.data);
                    let url = CDN + result.data


                    File.download(result.data)
                        .then(result => {
                            var downloadedFile = new Blob([result.data], { type: 'application/pdf' });
                            saveAs(downloadedFile, vm.quote.id + '.pdf');
                        })
                        .catch(err => {
                            ngNotify.error('You might need to wait a few minutes before downloading more files.')
                            console.error(err)
                        })
                        .finally($ionicLoading.hide)

                    // $window.open(url);                 
                    // window.location = url
                    // win.focus();
                })
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

        vm.sendQuote = function () {
            $ionicLoading.show()
            console.log('quoting mode: ' +  vm.displayMarkup )
            Quote.pdf(vm.quote.id, { displayMarkup: vm.displayMarkup, action: 'email' })
                .then(result => {
                    ngNotify.success(result.data)
                    console.log(result.data);                                        
                })
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }
        

    }

    function Config ($stateProvider) {
        $stateProvider
            .state('app.quote', {
                url: "/quote/:quoteId",
                cache:false,
                params: {
                    selected: null
                },
                views: {
                      'menuContent': {
                            template:`
                            <ion-view title="Quote Detail">
                                <quote-detail></quote-detail>
                            </ion-view>`
                      }
                }
            })
    }

})();
