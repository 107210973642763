(function () {
  'use strict';
  angular
    .module('app.auth.interceptor', [])
      .factory('AuthInterceptor', AuthInterceptor)
      .config($httpProvider => $httpProvider.interceptors.push('AuthInterceptor') );

  function AuthInterceptor($q, $localStorage, $injector) {

    return {
      request:function (config) {
        config.headers = config.headers || {};
        let token = $localStorage.token;
        if (token) {
          config.headers.Authorization = 'Bearer ' + token;
        }
        return config;
      },
      responseError:function (rejection) {
        if (rejection.status === 401) {
          console.log('Not logged')
          let back = $injector.get('$state').current.name;
          console.log(back);
          $injector.get('$state').transitionTo('auth.login',{backTo:back});
        }
        if (rejection.status === 403) {
          console.log('Unauthorized device')                    
          $injector.get('$state').transitionTo('auth.device');
        }
        if (rejection.status === 503) {
          console.log('System shutdown')                    
          $injector.get('$state').transitionTo('auth.maintenance');
        }
        return $q.reject(rejection);
      }
    };

  }
})();
