(function () {
    'use strict';
    angular
        .module('app.managers',[
            'app.managers.service',
            'app.managers.user'
        ])
            .config(Config)
            .component('managersDashboard', {
                templateUrl: 'views/managers/dashboard.html',
                controller: Ctrl
            })
    
    function Ctrl (Manager, ngNotify) {
        var vm = this

        vm.$onInit = function () {
            Manager.dashboard()
                .then(result => {
                    vm.users = result.data.users
                    vm.quotes = result.data.quotes
                    console.log(vm.quotes);
                })
                .catch(ngNotify.danger)
        }
    }

    function Config($stateProvider) {
        $stateProvider
            .state('app.managers', {
                url: "/managers",
                cache:false,
                views: {
                      'menuContent': {
                            template:`
                            <ion-view title="Territory Managers Dashboard"><managers-dashboard></managers-dashboard></ion-view>`
                      }
                }
            })
    }
})();