(function () {
    'use strict';

    angular
        .module('app.quotes',[

            'app.quotes.service', // http requests
            'app.quotes.filters', // shared functions for building system modules
            'app.quotes.tabs', // cooling-system, heat pump and packaged unit tabs
            'app.quotes.catalogs',// Load catalogs in localStorage to save loading times
            'app.quotes.history', // check quotes generated previously
            'app.quotes.detail',
            /*  build complete systems  */
            'app.quotes.cooling-system',
            'app.quotes.system-instructions',
            'app.quotes.condenser-airhandler',
            'app.quotes.packaged-unit',
            'app.quotes.heat-pump',
            'app.quotes.mini-split',
            'app.quotes.accessories'
        ])

})();
