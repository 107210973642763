(function () {
    'use strict';
    angular
        .module('app.admin.merge-products',[])
            .config(Config)
            .component('mergeProducts',{
                templateUrl: ``,
                controller: ComponentCtrl
            })

    function ComponentCtrl ( ngNotify, $ionicLoading, $localStorage, Product, $ionicPopup, $scope, ionicToast, $state, $stateParams) {

        var vm = this

        vm.$onInit = function () {
            
            getProducts()            

        }       

        

        function getProducts () {            

            Product.all()
                .then(result => {
                    let data = result.data
                    


                })
                .catch(ngNotify.danger)                
        }

        

    }


    function Config ($stateProvider) {

        $stateProvider
            .state('admin.merge-products', {
                url: "/merge-products",
                cache:false,
                views: {
                      'menuContent': {
                            template:`
                            <ion-view title="Admin - Merge products">
                                <merge-products></merge-products>
                            </ion-view>`
                      }
                }
        })
    }

})();
