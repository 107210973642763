(function () {
    angular
    .module('app.print',[])
    .directive('printDiv', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {

                element.bind('click', function (evt) {
                    console.log('Clicky!');
                    evt.preventDefault();
                    printElem(attrs.printDiv);
                });

                function printElem(elem) {
                    printWithIframe($(elem).html());
                }

                function printWithIframe(data) {
                    if ($('iframe#printf').size() ===  0) {
                        // an iFrame is added to the html content, then your div's contents are added to it and the iFrame's content is printed
                        $('html').append('<iframe id="printf" name="printf"></iframe>');

                        var mywindow = window.frames.printf;
                        //'@import url(\'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css\');' +
                        mywindow.document.write(`
                        <html>
                            <head>
                                <title></title>
                                <style>
                                body {
                                    color:#444;
                                    font-family:Arial, Helvetica, sans-serif
                                }
                                table { width:90%; }
                                th { font-weight: bold; }
                                @page { margin: 25mm 0mm 25mm 5mm; }
                                </style>
                            </head>
                            <body>
                                <div>
                                 ${data}
                                </div>
                            </body>
                        </html>`);

                        $(mywindow.document).ready(function () {
                            mywindow.print();
                            // The iFrame is removed 2 seconds after print() is executed, which is enough for me, but you can play around with the value
                            setTimeout(() => $('iframe#printf').remove(), 2000);
                        });
                    }

                    return true;
                }
            }
        };
    });
})();
