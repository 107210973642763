(function () {

    'use strict';

    angular
        .module('app.admin.report',[])
            .config(Config)
            .component('adminReport',{
                templateUrl: 'views/admin-reports/report.html',
                controller: ComponentCtrl
            })

    function ComponentCtrl (Admin, ngNotify, $ionicLoading, $stateParams, $state, $ionicPopup, Product) {

        var vm = this

        vm.$onInit = function () {

            vm.productTypes = $localStorage.catalogs.productTypes;

            Admin.users()
                .then(result => vm.users = result.data)
                .catch(ngNotify.danger)

            // get date from one month ago
            var today = new Date()
            var priorDate = new Date().setDate(today.getDay()-7)
            priorDate = new Date(priorDate)

            vm.form = {
                from: priorDate,
                to: today,
                userId: null
            }

            // getReports(vm.form)
            
        }

        vm.getProductsReport = function () {
            getReports(vm.form)
        }

        function getReports (form) {
            // Admin.usersReport(form)
            //     .then(result => vm.users = result)
            //     .catch(ngNotify.danger)
            $ionicLoading.show({
                template: 'This could take a few moments, the longer the date range the longer it will take to load'
            })

            console.log(form);
            Admin.productsReport(form)
                .then(result => {
                    vm.products = result.data
                    console.log(vm.products);
                    vm.groups = _.groupBy(vm.products,'productTypeId')
                    vm.unitTypes = _.groupBy(vm.products,'unitTypeId')
                    vm.models = _.groupBy(vm.products,'modelNo')

                    vm.modelsList = []
                    _.each(vm.models, (value, key) => {
                        let productTypeId = 0
                        if (value[0].productTypeId ) {
                            productTypeId = value[0].productTypeId
                        }
                        vm.modelsList.push({ 
                            modelNo: key, 
                            quantity: value.length, 
                            productTypeId: productTypeId
                        })
                    })
                    vm.completeSystems = _.groupBy(vm.products,'ahri')
                    

                })
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

       

     

    }

    function Config ($stateProvider) {
        $stateProvider
            .state('admin.report', {
                url: "/report",
                cache:false,
                params: {
                    active: null
                },
                views: {
                      'menuContent': { template:`<ion-view title="Admin - Reports">
                      <ion-content><admin-report></admin-report>
                      </ion-content></ion-view>` }
                }
            })
    }
})();
