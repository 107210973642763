(function () {
  'use strict';

    // const productionServer = 'http://app.' + window.location.hostname
    const productionServer = 'http://app.hi.crossbridge.ws'
    
    const localServer = 'http://localhost:50585'
    const isDevMode = window.location.hostname === 'localhost'

    const FPConfig = { 
      excludeUserAgent: true, 
      excludeScreenResolution: true ,
      excludePlugins: true,
      excludeIEPlugins: true,
      excludeScreenResolution: true
    }

    angular
      .module('app.config', [])
        .constant('API', isDevMode ? localServer : productionServer)
        .constant('CDN','https://s3-us-west-1.amazonaws.com/howardindustries/')
        .constant('FPConfig', FPConfig)

})();
