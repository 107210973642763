(function () {
    'use strict';

    angular
        .module('app.quotes.history', [])
            .config(config)
            .component('quoteHistory', {
                templateUrl: 'views/quotes-history/history.html',
                controller: Component
            })

    function Component (Quote, $ionicLoading, ngNotify, $localStorage) {
        var vm = this

        vm.$onInit = function () {

            $ionicLoading.show();
            vm.displayMarkup = $localStorage.displayMarkup
            if ($localStorage.user && $localStorage.user.markup) {
                vm.markup = $localStorage.user.markup
            }

            Quote.fetch()
                .then(data => vm.quotes = data.data)
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

    }

    function config ($stateProvider) {

        $stateProvider
            .state('app.quotes-history', {
                url: "/quotes-history",
                cache:false,
                views: {
                      'menuContent': {
                            template:`<ion-view title="Quote History"><quote-history></quote-history></ion-view>`
                      }
                }
            })
    }

})();
