(function () {
    'use strict';
    angular
        .module('app.cart.add', [])
            .component('addToCart', {
                template: `
                <p style="padding:20px; text-align:center">
                    <button class="button button-positive" ng-click="$ctrl.addToCart($ctrl.selected,'Cooling System')">
                        Add to Cart
                    </button>
                </p>`,                
                controller: Ctrl
            })
    function Ctrl (Cart) {

        var vm = this

        vm.addToCart = Cart.add
    }
})();
