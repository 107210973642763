(function () {
    'use strict';

    angular
        .module('app.cart',[
            'app.cart.service',
            'app.cart.icon',
            'app.cart.component'
        ])
})();
