(function () {
    'use strict';
    angular
        .module('app.files.service',[])
            .factory('File', function ($http, API) {
                return {

                    upload: function (file, folder) {
                        var fd = new window.FormData();
                        fd.append('file', file);
                        let endpoint = `${API}/api/files`

                        if (folder) endpoint += `?folder=${folder}`

                        return $http.post(endpoint, fd, {
                            transformRequest: angular.identity,
                            headers: { 'Content-Type': undefined }
                        })
                    },
                    download: function (file) {
                        let endpoint = `${API}/api/files/download?file=${file}`
                        return $http.get(endpoint, { responseType: 'arraybuffer' })  
                    },
                    getFilesFromFolder: function (folder) {
                        let endpoint = `${API}/api/files?folder=${folder}`
                        return $http.get(endpoint)
                    },

                    submitals: function (model) {
                        let endpoint = `${API}/api/files/specs?ModelNoPartial=${model}`
                        return $http.get(endpoint);
                    },

                    submital: function (model) {
                        let endpoint = `${API}/api/files/specs?modelNo=${model}`
                        return $http.get(endpoint);
                    },

                    submittals: function (model) {
                        let endpoint = `${API}/api/files/submittals?modelNo=${model}`
                        return $http.get(endpoint);
                    },

                    certificate: function (ahri) {
                        let endpoint = `${API}/api/files/certificates?AHRI=${ahri}`
                        return $http.get(endpoint);
                    },

                    brochure: function (modelNo) {
                        let endpoint = `${API}/api/files/brochures?modelNo=${modelNo}`
                        return $http.get(endpoint);
                    },

                    manual: function (modelNo) {
                        let endpoint = `${API}/api/files/manuals?modelNo=${modelNo}`
                        return $http.get(endpoint);
                    },

                    delete: function (key) {
                        let endpoint = `${API}/api/files/?key=${key}`
                        return $http.delete(endpoint);
                    },

                }
            })
})();
