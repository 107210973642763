(function () {
    'use strict';

    angular
        .module('app.cart.service',[])
            .factory('Cart',function ( $localStorage, $rootScope, ngNotify, $ionicPopup, $http, API, $state) {


                // save current state of the shopping cart
                function sync () {
                    console.log('Sync cart');
                    let data = JSON.stringify($localStorage.cart)                    
                    return $http.post(API + '/api/account/cart', { data: data })
                        .then(result => console.log(result.data))
                        .catch(err => console.error(err))
                }

                function suggestAccessories () {
                    $ionicPopup.confirm({
                            title: 'Add Accesories',
                            template: 'Do you want to add one or more accessories to this system',
                            cancelText: 'No',
                            okText: 'Yes',
                        }).then(function(res) {
                            if (res) {                                    
                                return $state.go('app.accessories')
                            }
                             $state.go('app.cart')
                        });
                }

                return {                    
                    getItems: function () {
                        return $localStorage.cart
                    },
                    // shopping cart actions
                    add: function (item) {     
                        
                        if (!$localStorage.cart) $localStorage.cart = []
                        // default value, for some reason the select box is not detecting 1 when set as an integer, that why we are using a string: "1"
                        item.quantity = '1'
                    
                        if (item.ahri) {
                            let duplicatedItem = $localStorage.cart.find(c => c.ahri === item.ahri)
                            if (duplicatedItem) {
                                return ngNotify.warning('This system is already in the shopping cart')
                            }
                        } else {
                            let duplicatedItem = $localStorage.cart.find(c => c.modelNo === item.modelNo)
                            if (duplicatedItem) {
                                return ngNotify.warning('This product is already in the shopping cart')
                            }
                        }

                        

                        item.productModels = []
                        // create an array of models in order to avoid having to look for each model column name, IE:
                        // { condenserModelNo: 111, airHandlerModelNo: 123 }
                        // Becomes simply:
                        // [111, 123]
                        _.each(item, (value, key)=> {
                            if (key.indexOf('odelN') > -1) {
                                if (value && value.indexOf('odelNo')===-1) {
                                    item.productModels.push(value)
                                }
                            }
                        })

                        console.log(item.productModels);


                        $localStorage.cart.push(item)
                        $rootScope.$broadcast('updateCart')
                        ngNotify.success('System successfully added to your Quoting Cart')

                        sync()

                        // ask for accessories only when adding a complete system
                        if (item.ahri) {
                            suggestAccessories()
                        }

                        
                    },
                    // remove element from shoppig cart
                    remove: function (index) {
                        console.log('index: ' + index);
                        $localStorage.cart.splice(index, 1)
                        $rootScope.$broadcast('updateCart')
                        console.log($localStorage.cart.length);

                        sync()
                    },

                    suggestAccessories: suggestAccessories,

                    sync: sync

                };
            });
})();
