(function () {

    angular
        .module('app.admin.edit-product', [])
            .config(config)
            .component('adminProduct', {
                templateUrl:`views/products/edit-product.html`,
                controller: Ctrl
            })

    function Ctrl (Product, $ionicLoading, $stateParams, ngNotify, $localStorage, $ionicPopup, API, Upload, CDN, File) {

        var vm = this

        vm.cdn = CDN

        vm.fileType = 'submitals'

        vm.$onInit = function () {
            vm.catalogs = $localStorage.catalogs

            getProduct()
            

            // load catalogs for the select boxes
            File.getFilesFromFolder('brochures')
                .then(result => vm.brochures = result.data)
                .catch(err => console.error(err))

            File.getFilesFromFolder('media')
                .then(result => vm.media = result.data)
                .catch(err => console.error(err))

            File.getFilesFromFolder('manuals')
                .then(result => vm.manuals = result.data)
                .catch(err => console.error(err))
            
        }

        vm.reset = () => getProduct()

        vm.save = function () {

            vm.product.specs = JSON.stringify(vm.specs)
            $ionicLoading.show()
            Product.update(vm.product)
                .then(ngNotify.success)
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

        vm.upload = function (type = null) {
            console.log(vm.file);
            $ionicLoading.show();
            Upload.upload({
                // url: `${API}/api/files?fileType=${vm.fileType}&id=${$stateParams.id}`,
                url: `${API}/api/products/${vm.product.modelNo}?fileType=${type}`,
                method: 'POST',
                file: vm.file
            }).then(function (resp) {
                ngNotify.success(resp.data.message)
                $ionicLoading.hide()
                getProduct()
            }, function (resp) {
                $ionicLoading.hide()
                console.log('Error status: ' + resp.status);
                console.error(resp.data);
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
            });
        }
        
        vm.updateSpecFields = () => {
            console.log('Update field specs')
            if (!vm.product.specs || vm.product.specs == '{}') {
                let specsFields = $localStorage.catalogs.productTypes
                    .find(pt => pt.id == vm.product.productTypeId)

                console.log(specsFields);
                vm.specs = {}
                if (specsFields && specsFields.fields) specsFields.fields.forEach(field => vm.specs[field] = '')
            } else {                        
                vm.specs = JSON.parse(vm.product.specs||null)
            }
        }

        vm.remove = function () {

            let sure = $ionicPopup.confirm({
                title:'Delete product',
                template: 'Do you really want to remove this product from the database?'
            })

            sure.then(yes => {
                if (yes) {
                    $ionicLoading.show()
                    Product.remove(vm.product.id)
                        .then(data => {
                            ngNotify.success(data)
                            window.history.back()
                        })
                        .catch(ngNotify.danger)
                        .finally($ionicLoading.hide)
                }
            })
        }       

        function getProduct () {

            $ionicLoading.show()
            let request, modelNo = $stateParams.modelNo;

            if ($stateParams.modelNo) {
                // consider only the first part of the model if it contains something like ABC23423/B
                if (modelNo.indexOf('/')>-1) {
                    modelNo = $stateParams.modelNo.split('/')
                    modelNo = modelNo[0]
                }                

                request = Product.getByModelNo($stateParams.modelNo)
            } else {
                console.log('asd');
                request = Product.show($stateParams.id)

            }

            request
                .then( data => {
                    vm.product = data.data

                    vm.updateSpecFields()


                    vm.originalProduct = vm.product

                   console.log(vm.product) 
                })                
                .catch(err => {
                    if (err.status === 404) {
                        vm.modelNo = $stateParams.modelNo
                        vm.notFound = true
                    } else {
                        ngNotify.danger(err.data)
                    }
                })
                .finally($ionicLoading.hide)                    

        }
    }

    function config ($stateProvider) {
        $stateProvider
            .state('admin.product', {
                url: "/product/:id?modelNo",
                cache:false,
                views: {
                      'menuContent': {
                            template:`
                            <ion-view title="Edit Product">
                                <admin-product></admin-product>
                            </ion-view>`
                      }
                  }
              })
    }

    function urlExists(url) {
        var http = new window.XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        return http.status!==404;
    }

})();
