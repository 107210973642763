(function () {
    'use strict';
  
    angular
        .module('app.auth.maintenance', [])
            .config(function ($stateProvider) {
                $stateProvider
                    .state('auth.maintenance', {
                        url: '/maintenance',
                        template: `
                        <ion-view class="login-view auth-view" cache-view="false">
                            <ion-content scroll="false">
                            
                                <div class="row animated bounceInDown">
                                    <div class="col col-center">
                                            <div class="card login-container padding" style="border-radius:10px" >
                                                <h2 align="center">System Unavailable</h2>
                                                <hr>
                                                <p>
                                                    The system is temporarily down for maintenance
                                                </p>
                                                <p>
                                                    We should be back online shortly. Thanks for your patience.
                                                </p>                                                
                                                <button class="button button-full button-assertive" ui-sref="auth.logout">
                                                    Log out
                                                </button>
                                                
                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ion-content>
                        </ion-view>                    
                        `,
                        controller: Ctrl,
                        controllerAs:'$ctrl'
                    })
                })
        
    function Ctrl () {

    }

  })();
  