(function () {
  'use strict';
  angular
    .module('app.user',[
      'app.user.service',
      'app.user.profile',
      'app.user.devices',
      'app.user.face',
    ]);
})();
