(function () {
    'use strict';

    angular
        .module('app.filters', [])

            .filter('removePDFExtension', function () {
                return function (input) {
                    if (input && typeof input === 'string') {
                        input = input.replace('.pdf', '')
                        input = input.replace('.png', '')
                        input = input.replace('.PNG', '')
                        input = input.replace('submitals/', '')
                        input = input.replace('brochures/', '')
                        input = input.replace('certificates/', '')
                        input = input.replace('manuals/', '')
                        input = input.replace('specs/', '')
                        input = input.replace('media/', '')
                        input = input.replace('guides/', '')
                        return input
                    } else {
                        return input
                    }
                }
            })
            .filter('startFrom', function() {
                return function(input, start) {                    
                    start = +start; //parse to int
                    if (input) {
                        return input.slice(start);
                    } else {
                        return input
                    }
                    
                }
            })
            .filter('undefinedAsEmpty', function () {
                return input => input === 'undefined' ? 'Empty': input 
            })
            .filter('none', function () {
                return input => input === 'undefined' || input == null || input === 'null' ? 'None': input 
            })
            .filter('title', function () {
                return function (input) {                    
                    let result = window._.startCase(input.replace('Id',''))
                    if (input.length <= 4) {
                        return result.toUpperCase()
                    } else {
                        return result
                    }                    
                }
            })
            .filter('catalog', function ($localStorage) {
                return function (input, catalog) {
                    if ($localStorage && $localStorage.catalogs) {
                        let selectedCatalog = $localStorage.catalogs[catalog]
                        let selected = selectedCatalog.find( b => b.id === parseInt(input) )
                        if (selected && selected.name) {
                            return selected.name
                        } else {
                            return input
                        }
                    } else {
                        return input
                    }
                }
            })
            .filter('rawHtml', function($sce){
              return function(val) {
                return $sce.trustAsHtml(val);
              };
            })

            .filter('parseDate', function() {
              return function(value) {
                  return Date.parse(value);
              };
            })

})();
