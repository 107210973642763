(function () {
    'use strict';
    
    angular
        .module('app.cart.icon',[])
            .component('cartIcon',{
                template: `
                <button class="button button-positive animated tada" ng-if="$ctrl.salesMode" ng-click="$ctrl.salesDescription()">
                    Sales Mode
                </button>
                <button class="button button-icon button-clear ion-android-cart" ui-sref="app.cart">                    
                    {{$ctrl.items}}
                </button>`,
                controller:ShoppingCartIconCtrl
            })

    function ShoppingCartIconCtrl (Quote, $rootScope, $localStorage, $ionicPopup, $state) {

        var vm = this        
        vm.items = $localStorage.cart.length

        vm.salesMode = $localStorage.displayMarkup

        // someone adds or removes an item from the shopping cart
        $rootScope.$on('updateCart',function () {
            vm.items = $localStorage.cart.length
        })

        vm.salesDescription = () => {
            $ionicPopup.alert({
                title:'Sales Mode is ON',
                template: 'The custom markup you defined in your settings will be applied to the system and products you quote.',
                buttons: [
                    {
                        text:'Ok',
                        type: 'button-light',                        
                    },
                    {
                        text:'Update Markup',
                        type: 'button-assertive',
                        onTap: e => $state.go('app.profile')                    
                    }                    
                ]
            });
                    
        }

    }

})();
