(function () {
    'use strict';

    /*
    Those are not **angular-filters** but a set of functions that help us to narrow down the products that match the users specifications

    The main objective of this file is to share functions between controlers, we will be passing the component controller as a parameter
    */

    angular
        .module('app.quotes.filters',[])

            .factory('QuoteFilters', function () {

                /**
                * This is a description                                
                * @param {Object} range - some string
                * @param {Array} list - filtered list
                * @param {string} field - field name                
                */
                function rangeFilters(range, list, field) {
                    
                    let filteredList = list

                    // only run this filter IF the list is already filled                    

                    // remove items that don't fit inside the range
                    if (range.from) {
                        filteredList = list.filter(item => parseFloat(item[field]) >= parseFloat(range.from) )
                    }

                    if (range.to) {                        
                        filteredList = filteredList.filter(item => item[field] <= range.to)
                    }
                    
                    return filteredList

                }

                let Filters = {
                    // initialize ranges to avoid undefined errors when applying filters
                    resetRanges: function (vm) {                        
                        vm.seerRange = {  }
                        vm.eerRange = {  }
                    },

                    // reset a single filter
                    resetField: function (field, vm) {
                        delete vm.form[field]
                        Filters.filterOut(vm)
                    },

                    // reset all the filters already set
                    resetFilters: function (vm) {
                        vm.filtered = vm.all
                        Object.keys(vm.form).forEach(key => vm.form[key] = null)

                        vm.seerRange = {}
                        vm.eerRange = {}
                        
                        vm.selected = null
                    },

                    // remove items from vm.filters list based on the user form selections
                    filterOut: function (vm) {
                        vm.filtered = vm.all;

                        console.log(vm.form);

                        Object.keys(vm.form).forEach(function (key) {
                            if (vm.form[key] === 'Choose option') delete vm.form[key]
                            // based on the selection, we will filter the array, each selection is represented as an attribute on this object
                            if (vm.form[key]) {
                                let selectedValue = String(vm.form[key]).toUpperCase()

                                vm.filtered = vm.filtered.filter(f => String(f[key]).toUpperCase() === selectedValue)
                            }
                        });

                        
                        vm.filtered = rangeFilters(vm.seerRange, vm.filtered, 'seer')
                        vm.filtered = rangeFilters(vm.eerRange, vm.filtered, 'eer')

                    },                    
                }

                return Filters;
            });
})();
