(function () {
    'use strict';

    angular
        .module('app.branch.list', [])
            .config(function($stateProvider) {
                $stateProvider
                    .state('admin.branches', {
                        url: "/branches",
                        cache:false,
                        params: {
                            selected: null
                        },
                        views: {
                              'menuContent': {
                                    template:`
                                    <ion-view title="Branches">
                                        <admin-branches></admin-branches>
                                    </ion-view>`
                              }
                        }
                    })
            })
            .component('adminBranches',{
                templateUrl:'views/branches/branches.html',
                controller: ComponentCtrl
            })

    function ComponentCtrl (ngNotify, $ionicLoading, Branch) {

        var vm = this

        vm.$onInit = function () {
            Branch.all()
                .then(result => vm.branches = result.data )
                .catch(ngNotify)
        }


    }
})();
