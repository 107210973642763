(function () {

    angular
        .module('app.admin.edit-system', [])
            .config(config)
            .component('adminSystem', {
                templateUrl:`views/admin-systems/edit-system.html`,
                controller: ctrl
            })

    function ctrl (Admin, $ionicLoading, $stateParams, ngNotify, $localStorage, $ionicPopup, System, Upload, API) {

        console.log('Edit system component');
        var vm = this        

        vm.$onInit = function () {

            vm.catalogs = $localStorage.catalogs
            vm.defaultFields = System.defaultFields

            getSystem()
        }

        vm.save = function () {            

            let updatedSystem = {}

            vm.defaultFields.forEach(field => updatedSystem[field] = vm.system[field])

            // fields specific for this unit type
            let jsonFields = {}
            _.each(vm.system, (value, key) => {                
                if (vm.systemDefaultFields.indexOf(key) == -1 ) {
                    jsonFields[key] = vm.system[key]
                }
            })

            console.log(updatedSystem)
            updatedSystem.data = JSON.stringify(jsonFields)

            $ionicLoading.show()
            System.update(updatedSystem)
                .then(ngNotify.success)
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

        vm.uploadCertificate = function() {
            $ionicLoading.show()
            Upload.upload({                
                url: `${API}/api/systems/${vm.system.ahri}`,
                method: 'POST',
                file: vm.file
            }).then(function (resp) {
                ngNotify.success(resp.data.message)
                $ionicLoading.hide()
                getSystem()
            }, function (resp) {
                $ionicLoading.hide()
                console.log('Error status: ' + resp.status);
                console.error(resp.data);
            });
        }

        vm.remove = function () {

            $ionicPopup.confirm({
                title:'Delete system',
                template: 'Do you really want to remove this from the database?'
            }).then(yes => {
                if (yes) {
                    $ionicLoading.show()
                    System.remove($stateParams.ahri)
                        .then(data => {
                            ngNotify.success(data)
                            window.history.back()
                        })
                        .catch(ngNotify.danger)
                        .finally($ionicLoading.hide)
                }
            })
        }

        function getSystem () {
            $ionicLoading.show()
            System.show($stateParams.ahri)
                .then( data => {
                    vm.systemDefaultFields = Object.keys(data.data)
                    console.log(vm.systemDefaultFields);
                    vm.system = System.parseProperties(data.data)
                    console.log(vm.system)
                    vm.system.models = System.getModels(vm.system)
                })
                .catch(err => {
                    if (err.status === 404) {
                        vm.ahri = $stateParams.ahri
                        vm.notFound = true
                    } else {
                        ngNotify.danger(err.data)
                    }

                })
                .finally($ionicLoading.hide)
        }
    }

    function config ($stateProvider) {
        $stateProvider
            .state('admin.system', {
                url: "/system/:ahri",
                cache:false,
                views: {
                      'menuContent': {
                            template:`<ion-view title="Edit System">
                            <admin-system></admin-system></ion-view>`
                      }
                  }
              })
    }
})();
