(function () {

    angular

        .module('app.quotes.tabs',[])

            .component('systemTabs', {
                template: `
                <div style="height:40px">.</div>
                <label class="item item-input item-select item-text-wrap" ng-if="$ctrl.smallScreen">
                    <div class="input-label">
                        Build a system
                    </div>
                    <select ng-model="$ctrl.active" ng-change="$ctrl.go($ctrl.active)">
                        <option ng-repeat="(state, name) in $ctrl.systems"
                            ng-selected="state==$ctrl.active"
                            value="{{state}}">{{name}}</option>
                    </select>
                </label>
                <div class="button-bar" ng-if="!$ctrl.smallScreen">
                    <a class="button {{$ctrl.active=='cooling-system'?'gray':''}}" ui-sref="app.cooling-system" nav-transition="none">
                        Cooling System
                    </a>
                    <a class="button {{$ctrl.active=='condenser-airhandler'?'gray':''}}" ui-sref="app.condenser-airhandler" nav-transition="none">
                        Cooling + AH
                    </a>
                    <a class="button {{$ctrl.active=='heat-pump'?'gray':''}}" ui-sref="app.heat-pump" nav-transition="none">
                        Heat Pump
                    </a>
                    <a class="button {{$ctrl.active=='packaged-unit'?'gray':''}}" ui-sref="app.packaged-unit" nav-transition="none">
                        Packaged Unit
                    </a>
                    <a class="button {{$ctrl.active=='mini-split'?'gray':''}}" ui-sref="app.mini-split" nav-transition="none">
                        Mini Split
                    </a>
                </div>`,
                bindings: {
                    active: '@'
                },
                controller: ComponentCtrl
            })

    function ComponentCtrl ($state, $ionicHistory) {

        var vm = this

        vm.systems = {
            'cooling-system':'Cooling System',
            'heat-pump': 'Heat Pump',
            'condenser-airhandler': 'Cooling + AH',
            'packaged-unit': 'Packaged Unit',
            'mini-split': 'Mini Split'
        }

        vm.$onInit = function () {

            if (window.innerWidth < 1200) {
                vm.smallScreen = true
            }            

        }

        vm.go = function (url) {
            $ionicHistory.nextViewOptions({ disableAnimate: true });
            let state = 'app.' + url
            $state.go(state)
        }


    }
})();
