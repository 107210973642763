(function () {
    'use strict';

    angular
        .module('app.files.ahri',[])
            .component('ahriCertificate',{
                template: `
                <a class="button button-assertive button-outline margin10"
                    ng-disabled="!$ctrl.certificate"
                    target="_blank"
                    href="{{$ctrl.certificate}}">
                    AHRI Certificate
                </a>
                <a class="button button-assertive button-outline button-small"
                    ng-click="$ctrl.open()" ng-if="!$ctrl.certificate">
                    Certificate Not Found (yet)
                </a>`,
                controller:ComponentCtrl,
                bindings: {
                    code: '<'
                }
            })

    function ComponentCtrl (File, CDN) {

        var vm = this

        vm.$onInit = function () {

            if (vm.code) {
                vm.certificate = null
                search()
            }

        }

        // update file if code model changes
        vm.$onChanges = () => search()


        vm.open = function () {
            let url = 'https://www.ahridirectory.org/ahridirectory/pages/ac/defaultSearch.aspx'
            window.open(url, "MsgWindow", "width=500,height=500");

            // setTimeout(() => {
            //
            //     ahriWindow.window.theForm.__EVENTTARGET.value = 'cp1_search1_grid1_selectArgs'
            //
            //     var aux = [];
            //     var str = String(vm.code);
            //     aux[0] = str.replace('(D)','')
            //     aux[1] = 178
            //     aux[2] = String(vm.code)
            //
            //     ahriWindow.window.theForm.__EVENTARGUMENT.value = aux
            //
            //     ahriWindow.window.theForm.submit()
            // }, 5000)

        }


        function search () {
            File.certificate(vm.code)
                .then(data => {
                    data = data.data                    
                    if (data && data.key) {
                        vm.certificate = CDN + data.key
                    } else {
                        console.log('Certificate not found')
                    }
                })
                .catch(err => {
                    console.error(err.data)
                    vm.certificate = false
                })
        }

    }

})();
