(function () {
    'use strict';

    angular
        .module('app.quotes.cooling-system',[])
            .config(coolingSystemConfig)
            .component('coolingSystem',{
                templateUrl:'views/complete-systems/cooling-system.html',
                controller:ComponentCtrl
            })

    function ComponentCtrl (Quote, ngNotify, QuoteFilters, Cart, $localStorage, $stateParams, System, Helper, $timeout) {

        var vm = this

        vm.filtered = false

        vm.$onInit = function () {

            vm.catalogs = $localStorage.catalogs

            vm.coolingSystem = {
                furnace: true,
            }

            vm.form = {};

            // the idea is to store in this object the selected items for each subcategory
            vm.selected = {}

            // initialize ranges
            QuoteFilters.resetRanges(vm)
            

            // add selected item to the shopping cart
            vm.addToCart = Cart.add

            // load combinations table
            System.getByType(4)
                .then(result => {
                    vm.all = result.data.map(System.parseProperties)
                    vm.filtered = vm.all
                    console.log(vm.all[0]);
                    if ($stateParams && $stateParams.selected) {
                        let filterableFields = [
                            'condenserBrand',
                            'tonnage',
                            'coolingStages',                            
                            'condenserModelNo',
                            'furnaceBrand',
                            'furnaceEfficiency',
                            'heatingStages',
                            'heatingInputCapacityMbtu',
                            'furnaceAirflowDirection' ,
                            'furnaceModelNo',
                            'coilBrand',
                            'coilAirflowDirection',
                            'coilWidthHeight',
                            'coilModelNo',                            

                        ]

                        filterableFields.forEach(field => vm.form[field] = $stateParams.selected[field])
                        vm.selected = $stateParams.selected
                        vm.filterOut(vm);
                    }
                    groupResults()
                })
                .catch(ngNotify.danger)            
        }

        // remove items from vm.filters list based on the user form selections
        vm.filterOut = function (ctrl) {

            if (ctrl.coolingSystem.furnace === false) {
                delete vm.form.furnaceBrand
                delete vm.form.furnaceEfficiency
                delete vm.form.heatingStages
                delete vm.form.furnaceAirflowDirection
                delete vm.form.heatingInputCapacityMbtu
                delete vm.form.furnaceModelNo
            }

            if (ctrl.form.coilAirflowId === "7") {
                vm.filtered.filter(f => f.coilAirflowId === "1" || f.coilAirflowId === "2")
            } else {
                QuoteFilters.filterOut(ctrl)
            }


            // furnace checkbox :)
            if (ctrl.coolingSystem.furnace === false) {

                console.log('Ignore products with no furnace')                
                ctrl.filtered = ctrl.filtered.filter(i => i.furnaceModelNo === ""||i.furnaceModelNo === null)
                console.log(vm.filtered.length);
            }
            // show only possible answers for the unchecked fields
            groupResults()
        }


        // clear EER or SEER range
        vm.resetRange = function (range) {
            vm[range] = {}            
            vm.filterOut(vm)
            groupResults()
        }

        // reset all the filters already set
        vm.resetFilters = function (ctrl) {
            QuoteFilters.resetFilters(ctrl)
            ctrl.coolingSystem.furnace = true
            groupResults()
        }

        // reset a single filter
        vm.resetField = function (field, ctrl) {
            QuoteFilters.resetField(field, ctrl)            
            // show only possible answers for the unchecked fields
            groupResults()
        }

        // select a system to display full details
        vm.select = item => {
            vm.selected = null           

            // kind of a $scope.$apply to solve a bug that was causing the product box to not update
            $timeout(()=>{
                vm.selected = item
                vm.selected.models = System.getModels(item)
            }, 100)
            
            console.log(vm.selected)
        }

        // group columns for populating the dropboxes
        function groupResults() {

            let fields = [
                'tonnage',
                'seer',
                'eer',
                'coolingStageId',
                'heatingStageId',
                'furnaceEfficiency',
                'furnaceAirflowId',
                'coilAirflowId',
                'furnaceAirflowId',
                'mbtu',
                'coilWidthHeight',
                'condenserBrandId',
                'heatingInputCapacityMbtu',
                // new schema
                'coolingStages',
                'furnaceAirflowDirection',
                'coilAirflowDirection',
                'heatingStages',
                'condenserBrand',
                'furnaceBrand',
                'coilAirflow',
                'coilBrand',
                'furnaceAirflow',
                'condenserModelNo',
                'furnaceModelNo',
                'coilModelNo'

            ]

            // array to populate form select box for filtering the results
            vm.dropdown = {}

            for (let item in fields) {
                let field = fields[item]
                vm.dropdown[field] = Object.keys(_.groupBy(vm.filtered, field));

                vm.dropdown[field] = vm.dropdown[field].filter(o => o!='undefined')
                vm.dropdown[field] = vm.dropdown[field].sort(Helper.naturalSort)
            }

        }

    }


    function coolingSystemConfig ($stateProvider) {
        $stateProvider
            .state('app.cooling-system', {
                url: "/cooling-system",
                cache:false,
                params:{
                    selected: null
                },
                views: {
                      'menuContent': {
                            template:`<ion-view title="Build Cooling System">
                            <cooling-system></cooling-system></ion-view>`
                      }
                  }
              })
    }
})();
