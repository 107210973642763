(function () {
    'use strict';

    angular
        .module('app.files.installation-manual',[])
            .component('installationManual',{
                template: `
                    <a class="button button-assertive button-small button-outline margin10"
                        ng-if="$ctrl.manual"
                        target="_blank"
                        href="{{$ctrl.manual}}">
                        User Manual {{$ctrl.model}}
                    </a>
                    <a class="button button-assertive button-outline button-small margin10" ng-if="$ctrl.manual == null">
                        Manual Not Found
                    </a>`,
                controller: ComponentCtrl,
                bindings: { model: '<' }
            })

    function ComponentCtrl (File, CDN) {

        var vm = this
        vm.CDN = CDN

        console.log('installation manual');

        vm.$onInit = function () {
            
            search()

        }

        // update manual if the model number changes
        vm.$onChanges = () => search()


        function search () {
            File.manual(vm.model)
                .then(data => {                    
                    if (data && data.data && data.data.key) {
                        vm.manual = CDN + data.key
                    } else {
                        vm.manual = null
                    }
                })
                .catch(err => console.error(err.data))
        }


    }

})();
