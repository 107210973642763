(function () {

    'use strict';

    angular
        .module('app.managers.user',[])
            .config(Config)
            .component('userOverview',{
                templateUrl: 'views/managers/user-overview.html',
                controller: ComponentCtrl
            })

    function ComponentCtrl (Admin, ngNotify, $ionicLoading, $stateParams, $state, User, $ionicPopup, Product, CDN) {

        var vm = this

        vm.$onInit = function () {

            vm.cdn = CDN
            // set active tab
            vm.active = 'information'            
            getUser()
            
        }

        function getUser () {
            $ionicLoading.show()
            Admin.user($stateParams.id)
                .then(data => {
                    vm.user = data.data

                    console.log(vm.user);
                    if (vm.user.generatedQuotes) {                        
                        vm.user.generatedQuotes = vm.user.generatedQuotes.reverse()
                    }
                })
                .catch(err => {
                    console.error(err.data);
                    ngNotify.error('An error occurred while loading the user\'s information, please try again later')
                })
                .finally($ionicLoading.hide)
        }



    }

    function Config ($stateProvider) {
        $stateProvider
            .state('app.user', {
                url: "/user/:id",
                cache:false,
                params: {
                    active: null
                },
                views: {
                      'menuContent': { template:`<ion-view title="User Overview"><user-overview></user-overview></ion-view>` }
                }
            })
    }
})();
