(function () {
  'use strict';

  angular

    .module('app.admin.service', [])

        .factory('Admin', function (API, $http) {

            return {
                toggleSystemQuotingStatus: function () {
                    let endpoint = API + '/api/admin/'
                    return $http.patch(endpoint);
                },
                quotes: function (queryParams = '') {
                    let endpoint = API + '/api/admin/quotes' + queryParams;
                    return $http.get(endpoint);
                },
                users: function () {
                    let endpoint = API + '/api/admin/users';
                    return $http.get(endpoint);
                },
                user: function (id) {
                    let endpoint = API + '/api/admin/users/' + id;
                    return $http.get(endpoint);
                },
                remove: function (id) {
                    let endpoint = API + '/api/admin/users/' + id;
                    return $http.delete(endpoint);
                },
                updateUser: function (user) {
                    let endpoint = API + '/api/admin/users/' + user.id
                    return $http.put(endpoint, user)
                },
                toggleActive: function (id) {
                    let endpoint = API + '/api/admin/users/' + id ;
                    return $http.patch(endpoint);
                },
                toggleAdmin: function (id) {
                    let endpoint = API + '/api/admin/users/' + id + '/admin';
                    return $http.patch(endpoint);
                },
                toggleManager: function (id) {
                    let endpoint = API + '/api/admin/users/' + id + '/manager';
                    return $http.patch(endpoint);
                },
                toggleDevice: function (id) {
                    let endpoint = API + '/api/admin/devices/' + id ;
                    return $http.patch(endpoint);
                },
                addMultiplier: function (data) {
                    let endpoint = API + '/api/admin/multipliers/' ;
                    return $http.post(endpoint,data);
                },
                removeMultiplier: function (id) {
                    let endpoint = API + '/api/admin/multipliers/' + id ;
                    return $http.delete(endpoint);
                },
                deleteDevice: function (id) {
                    let endpoint = API + '/api/admin/devices/' + id ;
                    return $http.delete(endpoint);
                },
                addPrice: function  (data) {
                    let endpoint = API + '/api/admin/pricing';
                    return $http.post(endpoint, data);
                },
                removePrice: function  (id) {
                    let endpoint = API + '/api/admin/pricing/' + id;
                    return $http.delete(endpoint);
                },
                fetchPrices: function  () {
                    let endpoint = API + '/api/admin/pricing';
                    return $http.get(endpoint);
                },

                getSystem: function  (id) {
                    let endpoint = API + '/api/admin/systems/' + id;
                    return $http.get(endpoint);
                },
                removeSystem: function  (id) {
                    let endpoint = API + '/api/admin/systems/' + id;
                    return $http.delete(endpoint);
                },                
                addProductType: function  (data) {
                    let endpoint = API + '/api/products/product/types';
                    return $http.post(endpoint, data);
                },
                addUnitType: function  () {
                    let endpoint = API + '/api/systems/unit/types';
                    return $http.post(endpoint, data);
                },

                usersReport: function  (form) {
                    let endpoint = API + `/api/reports/users?from=${form.from}&to=${form.to}`;
                    return $http.get(endpoint);
                },
                productsReport: function  (form) {
                    // ?from=${form.from}&to=${form.to}
                    let endpoint = API + `/api/reports/products`;
                    return $http.post(endpoint, form);
                },

            }
        });
})();
