(function () {
    'use strict';

    angular
        .module('app.branch.service', [])
            .factory('Branch', function ($http, API) {

                return {

                    all:function () {
                        let endpoint = API + '/api/furnaces';
                        return $http.get(endpoint);
                    },
                    create:function (data) {
                        let endpoint = API + '/api/furnaces'
                        return $http.post(endpoint, data)
                    },
                    update:function (data) {
                        let endpoint = API + '/api/furnaces/' + data.id
                        return $http.put(endpoint, data)
                    }

                };
            });
})();
