(function () {
    'use strict';
    angular
        .module('app.admin.parser', [])
            .factory('ImportParser', function ($localStorage) {

                function findByName (catalog, name) {
                    if (!name) return ;
                    let itemFound = catalog.find(st => st.name.toUpperCase() === name.toUpperCase())
                    if (itemFound && itemFound.id) {
                        return itemFound.id
                    } else {
                        return null
                    }
                }

                return {

                    findByName: findByName,
                    coolingSystem: function (data) {

                        const catalogs = $localStorage.catalogs

                        let system = {}
                        if (data.Tonnage) {
                            system.Tonnage = parseFloat(data.Tonnage.replace(' Ton',''))
                        }

                        system.coolingStageId = findByName(catalogs.stages, data.CoolingStages)
                        system.heatingStageId = findByName(catalogs.stages, data.HeatingStages)
                        system.CondenserBrandId = findByName(catalogs.brands, data.CondenserBrand)
                        system.CoilBrandId = findByName(catalogs.brands, data.CoilBrand)
                        system.FurnaceBrandId = findByName(catalogs.brands, data.FurnaceBrand)

                        system.FurnaceAirflowId = findByName(catalogs.airflows, data.FurnaceAirflowDirection)
                        system.CoilAirflowId = findByName(catalogs.airflows, _.trim(data.CoilAirflowDirection))


                        system.AHRI = parseInt(data.Ahri)
                        system.EER = parseFloat(data.Eer)
                        system.SEER = parseFloat(data.Seer)
                        system.Capacity = parseInt(data.Capacity)
                        system.FurnaceEfficiency = parseInt(data.FurnaceEfficiency)
                        system.HeatingInputCapacityMbtu = parseInt(data.HeatingInputCapacityMbtu)
                        system.Voltage = data.Voltage
                        system.CondenserModelNo = data.CondenserModelNo
                        system.CoilModelNo = data.CoilModelNo
                        system.CoilWidthHeight = data.CoilWidthHeight
                        system.CoilMfgNo = data.CoilMfgNo
                        system.FurnaceModelNo = data.FurnaceModelNo

                        if (data.AirHandlerModelNo ||data.AirHandlerModelNumber) {
                            system.AirHandlerModelNo = data.AirHandlerModelNo ||data.AirHandlerModelNumber

                            // system.AirHandlerBrandId
                        }

                        return system
                    },

                    heatPump: function (data) {
                        const catalogs = $localStorage.catalogs

                        let system = {}
                        // Heat Pump ID
                        system.UnitTypeId = 3

                        system.CondenserTonnage = parseFloat(data.CondenserTonnage.replace(' Ton',''))
                        system.AirHandlerTonnage = parseFloat(data.AirHandlerTonnage.replace(' Ton',''))



                        system.HeatPumpStageId = findByName(catalogs.stages, data.HpHeatCoolStages)
                        system.AirHandlerStageId = findByName(catalogs.stages, data.AhHeatCoolStages)

                        system.HeatPumpBrandId = findByName(catalogs.brands, data.HeatPumpBrand)
                        system.AirHandlerBrandId = findByName(catalogs.brands, data.AirHandlerBrand)


                        system.AHRI = parseInt(data.Ahri)
                        system.EER = parseFloat(data.Eer)
                        system.SEER = parseFloat(data.Seer)
                        system.Voltage = data.Voltage
                        system.HeatPumpModelNo = data.HeatPumpModelNo
                        system.AirHandlerModelNo = data.AirHandlerModelNo

                        system.KW = parseFloat(data.Kw)
                        system.HKW = parseFloat(data.Hkw)
                        system.COP = parseFloat(data.Cop)
                        system.CFM = parseInt(data.Cfm)
                        system.Hspf = parseFloat(data.Hspf)
                        system.HeatCapacity = parseInt(data.HeatCapacity)
                        system.TotalCapacity = parseInt(data.TotalCapacity)

                        return system
                    },

                    packagedUnits: function (data) {
                        const catalogs = $localStorage.catalogs

                        let system = {}
                        if (data.Tonnage) {
                            system.Tonnage = parseFloat(data.Tonnage.replace(' Ton',''))
                        }

                        system.ModelNo = data.PackagedUnitModelNo

                        system.UnitTypeId = findByName(catalogs.unitTypes, data.UnitType)
                        system.BrandId = findByName(catalogs.brands, data.PackagedUnitBrand)
                        system.StageId = findByName(catalogs.stages, data.CoolingStages)
                        system.Voltage = data.Voltage

                        system.AHRI = parseInt(data.Ahri)
                        system.EER = parseFloat(data.Eer)
                        system.SEER = parseFloat(data.Seer)

                        system.KW = parseFloat(data.Kw)
                        system.CFM = parseInt(data.Cfm)
                        system.SensibleCapacity = parseInt(data.SensibleCapacity)
                        system.TotalCapacity = parseInt(data.TotalCapacity)

                        return system
                    }
                }
            })
})();
