(function () {
    'use strict';
    
    angular
        .module('app.products.price', [])
            .component('productPrice',{
                template: `
                
                <span class="hi-title" >
                    {{$ctrl.product.description}}
                </span>
                <span class="hi-error" ng-if="$ctrl.product == 'notfound'">Not Found</span>`,
                controller:ComponentCtrl,
                bindings: {
                    model: '<'
                }
            })

    function ComponentCtrl (Product) {

        var vm = this

        vm.$onInit = function () {

            if (vm.model) {                

                Product.getPrice(vm.model)
                    .then(data => {

                        vm.product = data.data
                        if (!vm.product) {
                            console.log(`Price not found for ${vm.model}`)
                            vm.product = 'notfound'
                        }

                    })
                    .catch(err => console.error(err.data) )
            }

        }


    }

})();
