(function () {

    'use strict';

    angular
        .module('app.quotes.condenser-airhandler',[])
            .config(config)
            .component('condenserAirhandler', {
                templateUrl: 'views/complete-systems/condenser-airhandler.html',
                controller: Ctrl
            })

    function Ctrl (Quote, ngNotify, $ionicLoading, QuoteFilters, Cart, System, $stateParams) {

        var vm = this

        vm.filtered = false

        vm.$onInit = function () {

            // forms
            vm.form = {};
            // the idea is to store in this object the selected items for each subcategory
            vm.selected = {}

            // initialize ranges
            QuoteFilters.resetRanges(vm)

            // add selected item to the shopping cart


            // load combinations table
            System.getByType(5)
                .then(result => {
                    vm.all = result.data.map(System.parseProperties)
                    vm.filtered = vm.all
                    console.log(vm.all[0])
                    
                    
                    if ($stateParams && $stateParams.selected) {
                        let filterableFields = [
                            'condenserBrand',
                            'tonnage',
                            'coolingStages',                            
                            'voltage',
                            'airHandlerBrand',
                            'airHandlerTonnage',
                            'airHandlerCoolStages',
                            'airHandlerModelNo'                            
                        ]

                        filterableFields.forEach(field => vm.form[field] = $stateParams.selected[field])
                        vm.selected = $stateParams.selected
                        vm.filterOut(vm);
                    }

                    groupResults()
                })
                .catch(ngNotify.danger)

        }

        vm.addToCart = Cart.add

        // remove items from vm.filters list based on the user form selections
        vm.filterOut = function (ctrl) {
            QuoteFilters.filterOut(ctrl)

            // show only possible answers for the unchecked fields
            groupResults()
        }

        // clear EER or SEER range
        vm.resetRange = function (range) {
            vm[range] = {}            
            vm.filterOut(vm)
            groupResults()
        }

        // reset all the filters already set
        vm.resetFilters = function (ctrl) {
            QuoteFilters.resetFilters(ctrl)
            QuoteFilters.resetRanges(vm)
            groupResults()
        }

        // reset a single filter
        vm.resetField = function (field, ctrl) {
            QuoteFilters.resetField(field, ctrl)            
            // show only possible answers for the unchecked fields
            groupResults()
        }

        vm.select = item => {
            vm.selected = item
            vm.selected.models = System.getModels(item)
            console.log(vm.selected)
        }

        // group columns for populating the dropboxes
        function groupResults() {

            let fields = [
                'eer',
                'seer',
                'condenserBrand',
                'condenserModelNo',
                'tonnage',
                'coolingStages',
                'voltage',
                'airHandlerBrand',
                'airHandlerTonnage',
                'airHandlerCoolStages',
                'airHandlerModelNo',
                'airHandlerModelNumber'
            ]

            // array to populate form select box for filtering the results
            vm.dropdown = {}

            for (let item in fields) {
                let field = fields[item]
                vm.dropdown[field] = Object.keys(_.groupBy(vm.filtered, field)).sort();
            }
            
        }

    }


    function config ($stateProvider) {
        $stateProvider
            .state('app.condenser-airhandler', {
                url: '/condenser-airhandler',
                cache:false,
                params:{
                    selected: null
                },
                views: {
                      'menuContent': { template:`<ion-view title="Build Cooling + AH System"><condenser-airhandler></condenser-airhandler></ion-view>` }
                }
            })
    }
})();
