(function () {
    'use strict';

    angular
        .module('app.files.brochure',[])
            .component('brochure',{
                // ng-if="$ctrl.brochure"
                template: `
                <span class="button button-outline button-disabled" ng-if="$ctrl.loading">
                    <ion-spinner></ion-spinner> Loading customer brochures
                </span>
                <a class="button button-positive button-outline button-small margin10"
                    ng-repeat="brochure in $ctrl.brochures"
                    target="_blank"
                    href="{{$ctrl.CDN + brochure.key}}">
                    Consumer Brochure {{$ctrl.text}} {{brochure.key|removePDFExtension}}
                </a>`,
                controller:ComponentCtrl,
                bindings: {
                    model: '<',
                    text: '@'
                }
            })

    function ComponentCtrl (File, CDN) {

        var vm = this

        vm.$onInit = function () {

            vm.loading = false

            vm.CDN = CDN

            if (vm.model) {
                vm.brochure = null
                search()
            }

        }

        vm.$onChanges = function () {
            vm.brochures = null
            if (vm.model) {
                search()
            }

        }


        function search () {
            
            vm.loading = true
            File.brochure(vm.model)
                .then(data => {
                    if (data && data.data ) {
                        vm.brochures = data.data
                    } else {
                        console.log(`Brochure not found ${vm.model}`)
                    }
                })
                .catch(err => console.error(err.data))
                .finally( ()=> vm.loading = false)
        }


    }



})();
