(function () {
    'use strict';
    angular
        .module('app.admin.users',[
            'ngSanitize',
            'ngCsv', // download CSV for users
        ])
        .config(function($stateProvider) {
            $stateProvider
                .state('admin.users', {
                    url: "/users",
                    cache:false,
                    views: {
                          'menuContent': {
                                template:`<ion-view title="Admin - Users"><admin-users></admin-users></ion-view>`
                          }
                    }
                    })
        })
        .component('adminUsers',{
            templateUrl:'views/admin-users/users.html',
            controller: AdminUsersCtrl
        })

        function AdminUsersCtrl (Admin, ngNotify, $ionicLoading, $ionicPopup) {

            var vm = this

            vm.$onInit = function () {

                getUsers()

            }

            function getUsers () {
                $ionicLoading.show()
                Admin.users()
                    .then(data => vm.users = data.data)
                    .catch(err=>{
                        console.error(err);
                        ngNotify.error('An error occurred while loading the users, please try again later')
                    })
                    .finally($ionicLoading.hide)
            }

            // gran access to user, by default users are inactive when they sign up
            vm.toggle = function (user) {
                $ionicLoading.show()
                Admin.toggleActive(user.id)
                    .then(data => {
                        $ionicLoading.hide()
                        ngNotify.success(data.data)
                        user.isActive = !user.isActive
                    })
                    .catch(err=>{
                        console.error(err);
                        ngNotify.error('An error occurred while loading the users, please try again later')
                    })
                    .finally($ionicLoading.hide)
            }

            vm.delete = function (user) {

                var confirmPopup = $ionicPopup.confirm({
                    title: 'Delete USer',
                    template: `Are you sure you want to remove this user: <br>
                        <strong>${user.firstName} ${user.lastName}</strong>?`
                })

                confirmPopup.then(res => {
                    if (res) {
                        $ionicLoading.show()
                        Admin.remove(user.id)
                        .then(data => {
                            getUsers()
                            ngNotify.success(data.data)

                        })
                        .catch(err=>{
                            console.error(err);
                            ngNotify.error('An error occurred while loading the users, please try again later')
                        })
                        .finally($ionicLoading.hide)
                    }
                })
            }



        }
})();
