(function () {
    'use strict';
    angular
        .module('app.user.devices',[])
            .config(function($stateProvider) {
                $stateProvider
                    .state('app.devices', {
                        url: "/devices",
                        cache:false,
                        views: {
                              'menuContent': {
                                    template:`<ion-view title="Devices"><ion-content><my-devices></my-devices></ion-content></ion-view>`
                              }
                        }
                        })
            })
            .component('myDevices',{
                template:`
                <ul class="list card">
                    <li class="item item-divider item-icon-left">
                        <i class="icon ion-iphone"></i>  My Devices
                    </li>
                    <li class="item item-icon-left item-button-right" ng-repeat="device in $ctrl.devices">
                        <i class="icon {{device.approved ? 'ion-checkmark':'ion-close-round'}}" style="font-size:14px"></i>
                        {{device.details}}
                        <span class="button button-positive button-xs"
                            ng-if="$ctrl.currentFingerPrint==device.fingerPrint">
                            Current device
                        </span>
                    </li>
                </ul>`,
                controller:UserDevicesCtrl
            })

    function UserDevicesCtrl(User, $localStorage, ngNotify, FPConfig) {

        var vm = this

        vm.$onInit = function () {

            console.log('Devices component');

            new Fingerprint2(FPConfig).get( (result, components) => {
                vm.currentFingerPrint = result
                console.log(result);
            } );

            vm.devices = $localStorage.user.userDevices;

            console.log(vm.devices)

            User.profile()
                .then(result => vm.user = result.data)                
                .catch(ngNotify.danger)
        }

    }


})();
