(function () {
    'use strict';

    angular
        .module('app.admin-managers.detail',[])
            .config(function($stateProvider) {
                $stateProvider
                    .state('admin.manager', {
                        url: "/manager/:id",
                        cache:false,
                        views: {
                              'menuContent': {
                                    template:`
                                    <ion-view title="Territory Managers">
                                        <manager-detail></manager-detail>
                                    </ion-view>`
                              }
                        }
                    })
            })
            .component('managerDetail',{
                templateUrl:'views/admin-managers/detail.html',
                controller: ComponentCtrl
            })

    function ComponentCtrl (Manager, ngNotify, $ionicLoading, $stateParams) {


        var vm = this

        $ionicLoading.show()
        Manager.show($stateParams.id)
            .then(data => vm.manager = data.data )
            .then(data => console.log(vm.manager) )
            .catch(ngNotify.danger)
            .finally($ionicLoading.hide)

        vm.update = function () {
            $ionicLoading.show()
            Manager.update(vm.manager)
                .then(ngNotify.success)
                .catch(ngNotify.danger)
                .finally($ionicLoading.hide)
        }

    }

})();
